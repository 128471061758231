export const environment = {
    production: true,
    debug: false,
    apiUrl: '/api',
    pdfUrl: 'https://bvgo-we-wa-p-prmfe.azurewebsites.net/api',
    applicationInsights: {
        instrumentationKey: '341f28c1-9525-4b87-94a2-bb5500cf4b82' //  hosted @ BVGO, resource group BVGO-WE-RSG-AP-PRM
    },

    msAuth: {
        issuer: 'https://login.microsoftonline.com/common/v2.0',
        clientId: '4f58e689-a35d-4737-851d-fa167ff1e04d',
        redirectUri: `${window.location.origin}/msal-auth`,
        scopes: ['api://4f58e689-a35d-4737-851d-fa167ff1e04d/user.read'],
        redirectToExternalEndSessionEndpointAfterLogout: false,
        redirectRoute: {
            afterAuthentication: ['/'],
            noAuthentication: ['/no-access'],
            failedAuthentication: ['/error']
        },
        // Use LocalStorage to allow same authentication across multiple browser tabs and persistence of tokens
        sessionStorageDriver: localStorage

    }
};
