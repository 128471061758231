import {Injectable} from '@angular/core';
import {ApiService, ToastService, UserService} from '../';
import {IPageableProjects, IProject, IProjectMandateDetails, IUser, LibraryEntry, Project, ProjectDetails, ProjectLibraryItem, ProjectMandateDetails, QuickscanResult, IQuickscanResultData} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse, HttpResponse, HttpSentEvent} from '@angular/common/http';
import {IProjectDetailsData, IProjectLibraryItemData} from '../../models/project/project-data';
import {Member} from '../../models/project/project';
import {ConstructionCosts} from '../../enums';

@Injectable()
export class ProjectService {

    private userInfo: IUser;

    constructor(
        private apiService: ApiService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        userService.onUserInfoChange().subscribe((userInfo) => {
            this.userInfo = userInfo;
        });
        userService.getUserInfo().then((userInfo) => {
            this.userInfo = userInfo;
        });
    }

    public getProjects(page: number, pageSize: number, searchTerm?: string, searchCompany?: string, searchStatuses?: string[], searchClassifications?: string[],
                       searchConstructionCosts?: string): Promise<IPageableProjects> {
        return new Promise((resolve, reject) => {
            let additionalParameters = (searchTerm ? `&searchTerm=${encodeURIComponent(searchTerm)}` : '');
            additionalParameters += (searchCompany ? `&searchCompanyName=${encodeURIComponent(searchCompany)}` : '');
            additionalParameters += (searchStatuses && searchStatuses.length > 0 ? `&searchStatuses=${encodeURIComponent(searchStatuses.join('||'))}` : '');
            additionalParameters += (searchClassifications && searchClassifications.length > 0 ? `&searchClassifications=${encodeURIComponent(searchClassifications.join('||'))}` : '');
            if (searchConstructionCosts) {
                if (searchConstructionCosts === ConstructionCosts.LESS_THAN_10M) {
                    additionalParameters += '&upperBoundConstructionCosts=9999999';
                } else if (searchConstructionCosts === ConstructionCosts.BETWEEN_10M_AND_25M) {
                    additionalParameters += '&lowerBoundConstructionCosts=10000000&upperBoundConstructionCosts=24999999';
                } else if (searchConstructionCosts === ConstructionCosts.BETWEEN_25M_AND_50M) {
                    additionalParameters += '&lowerBoundConstructionCosts=25000000&upperBoundConstructionCosts=50000000';
                } else if (searchConstructionCosts === ConstructionCosts.GREATER_THAN_50M) {
                    additionalParameters += '&lowerBoundConstructionCosts=50000001';
                }
            }
            this.apiService.get(`/project?page=${page}&pageSize=${pageSize}${additionalParameters}`).then((projectResponse: HttpResponse<IPageableProjects>) => {
                projectResponse.body.content = projectResponse.body.content.map(data => new Project(data));
                resolve(projectResponse.body);
            }).catch((error) => {
                this.toastService.openError(error.status, this.translateService.instant('ERRORS.projects_not_updated'));
                reject(error);
            });
        });
    }

    public createProject(data): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post('/project', data).then((projectResponse: HttpSentEvent) => {
                resolve('ok');
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else {
                    const errorMessage = this.translateService.instant('ERRORS.project_not_created');
                    this.toastService.openError(error.status, errorMessage);
                }
                reject(error);
            });
        });
    }

    public getProjectDetails(projectId: number): Promise<ProjectDetails> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/project/${projectId}/details`).then((projectResponse: HttpResponse<IProjectDetailsData>) => {
                const project: ProjectDetails = new ProjectDetails(projectResponse.body);
                resolve(project);
            }).catch((error) => {
                this.toastService.openError(error.status, this.translateService.instant('ERRORS.project_not_found'));
                reject(error);
            });
        });
    }

    public saveProjectDetails(projectDetails: ProjectDetails): Promise<ProjectDetails> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/project/${projectDetails.id}/details`, projectDetails.toApiModel()).then((projectResponse: HttpResponse<IProjectDetailsData>) => {
                const project: ProjectDetails = new ProjectDetails(projectResponse.body);
                resolve(project);
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else if (error.status === 413) {
                    this.toastService.openError(error.status, this.translateService.instant('ERRORS.filesize_too_high'));
                } else {
                    this.toastService.openError(error.status, this.translateService.instant('ERRORS.project_not_updated'));
                }
                reject(error);
            });
        });
    }

    public getQuickscanResult(projectDetails: ProjectDetails, forTis: boolean, persist: boolean = false): Promise<QuickscanResult> {
        return new Promise((resolve, reject) => {
            let url = `/project/${projectDetails.id}/calculate?forTis=${forTis}`;
            if (persist) {
                url += '&persist=true';
            }

            this.apiService.post(url, projectDetails.toApiModel()).then((calculationResponse: HttpResponse<IQuickscanResultData>) => {
                resolve(new QuickscanResult(calculationResponse.body));
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else {
                    this.toastService.openError(error.status, this.translateService.instant('ERRORS.quickscan_result_not_retrieved'));
                }
                reject(error);
            });
        });
    }

    public addSelectedItems(projectDetails: ProjectDetails, libraryType: string, selectedItems: LibraryEntry[]): Promise<ProjectDetails> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/project/${projectDetails.id}/add-library-items/${libraryType}`, selectedItems).then((projectResponse: HttpResponse<IProjectDetailsData>) => {
                const project: ProjectDetails = new ProjectDetails(projectResponse.body);
                resolve(project);
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else {
                    this.toastService.openError(error.status, this.translateService.instant(`ERRORS.library_type.${libraryType}.not_updated`));
                }
                reject(error);
            });
        });
    }

    public addProjectLibraryItem(projectDetails: ProjectDetails, libraryType: string, specificLibraryItem: ProjectLibraryItem): Promise<ProjectDetails> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/project/${projectDetails.id}/add-project-library-item/${libraryType}`, specificLibraryItem).then((projectResponse: HttpResponse<IProjectDetailsData>) => {
                const project: ProjectDetails = new ProjectDetails(projectResponse.body);
                resolve(project);
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else {
                    this.toastService.openError(error.status, this.translateService.instant(`ERRORS.library_type.${libraryType}.not_updated`));
                }
                reject(error);
            });
        });
    }

    public updateProjectLibraryItem(projectLibraryItem: ProjectLibraryItem): Promise<ProjectLibraryItem> {
        return new Promise((resolve, reject) => {
            this.apiService.put(`/project/update-library-items/${projectLibraryItem.id}`, {id: undefined, ...projectLibraryItem}).then((response: HttpResponse<IProjectLibraryItemData>) => {
                resolve(new ProjectLibraryItem(response.body));
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else {
                    this.toastService.openError(error.status, this.translateService.instant(`ERRORS.library_type.${projectLibraryItem.libraryType}.not_updated`));
                }
                reject(error);
            });
        });
    }

    public deleteProjectLibraryItem(projectLibraryItem: ProjectLibraryItem): Promise<void> {
        return new Promise((resolve, reject) => {
            this.apiService.delete(`/project/delete-library-items/${projectLibraryItem.id}`).then(() => {
                resolve();
            }).catch((error) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    this.toastService.handleHttpError(error);
                } else {
                    this.toastService.openError(error.status, this.translateService.instant(`ERRORS.library_type.${projectLibraryItem.libraryType}.not_deleted`));
                }
                reject(error);
            });
        });
    }

    public isOwner(project: ProjectDetails): boolean {
        return project && this.userInfo && this.userInfo.uniqueName === project.owner.emailAddress
            && this.userService.userHasProperOwnerRoles(this.userInfo);
    }

    public isProjectRiskManager(project: ProjectDetails): boolean {
        return project && this.userInfo && project.riskManager && this.userInfo.uniqueName === project.riskManager.emailAddress
            && this.userService.userHasProperOwnerRoles(this.userInfo);
    }

    public canEditProject(project: ProjectDetails): boolean {
        return project && (this.isOwner(project) || this.isProjectRiskManager(project) || this.isCompanyEditMember(project) || this.isProjectEditMember(project));
    }

    public canEditProjectDetails(project: ProjectDetails): boolean {
        return project && (this.isOwner(project) || this.isProjectRiskManager(project) || this.isCompanyEditMember(project));
    }

    public canEditProjectMembers(project: ProjectDetails): boolean {
        // Currently this is the same set
        return this.canEditProjectDetails(project);
    }

    public canEditQuickscan(project: ProjectDetails): boolean {
        return project && (this.canEditProjectDetails(project) || this.isQuickscanEditor(project));
    }

    private isQuickscanEditor(project: ProjectDetails) {
        const username = this.userInfo ? this.userInfo.uniqueName : undefined;

        return project.members.some((projectMember) => projectMember.user.emailAddress === username && projectMember.quickscanEditor);
    }

    public canEditProjectLibraryItems(project: ProjectDetails): boolean {
        return project && (this.canEditProjectDetails(project) || this.isProjectLibraryItemEditor(project));
    }

    private isProjectLibraryItemEditor(project: ProjectDetails) {
        const username = this.userInfo ? this.userInfo.uniqueName : undefined;

        return project.members.some((projectMember) => projectMember.user.emailAddress === username && projectMember.projectLibraryItemEditor);
    }

    public canEditTis(project: ProjectDetails): boolean {
        return project && (this.canEditProjectDetails(project) || this.isTisEditor(project));
    }

    private isTisEditor(project: ProjectDetails) {
        const username = this.userInfo ? this.userInfo.uniqueName : undefined;

        return project.members.some((projectMember) => projectMember.user.emailAddress === username && projectMember.tisEditor);
    }

    public canManageCompany(project: ProjectDetails) {
        if (project && this.userInfo) {
            if (this.userService.isRiskManager()
                // Being risk manager is nice, but it needs to be for the proper type.
                && this.userInfo.companyTypes.some((companyType) => companyType.id === project.companyTypeId)) {
                return true;
            }
           return this.userService.userHasProperAdminRoles(this.userInfo, project);
        }
        return false;
    }

    public isCompanyTenderManager(project: ProjectDetails): boolean {
        const username = this.userInfo ? this.userInfo.uniqueName : undefined;

        if (this.userInfo && project) {
            return this.userInfo.manageableCompanies.find(
                (company) => company.name === project.company &&
                    company.tenderManagers.find((tenderManager) => {
                        return tenderManager.emailAddress === username;
                    })
            ) !== undefined;
        }

        return false;
    }

    private isProjectEditMember(project: ProjectDetails) {
        const username = this.userInfo ? this.userInfo.uniqueName : undefined;
        return project.members.some((projectMember) => projectMember.user.emailAddress === username && projectMember.isProjectEditor());
    }

    private isCompanyEditMember(project: ProjectDetails): boolean {
        const username = this.userInfo ? this.userInfo.uniqueName : undefined;

        return this.containsEditMember(username, project.companyMembers);
    }

    private containsEditMember(username: string, members: Member[]): boolean {
        return members.find((member) => member.user.emailAddress === username && member.hasEditRights) !== undefined;
    }

    public toExecution(project: IProject): Promise<ProjectMandateDetails> {
        return this.apiService.put(`/project/${project.id}/in_execution`, project).then((projectResponse: HttpResponse<IProjectMandateDetails>) => new ProjectMandateDetails(projectResponse.body)).catch((error) => {
            this.toastService.openError(error.status, this.translateService.instant('ERRORS.project_to_execution_nok'));
            return Promise.reject(error);
        });
    }

    public toArchived(projectDetails: ProjectDetails): Promise<void> {
        return this.apiService.delete(`/project/${projectDetails.id}`);
    }

    public toClosed(projectDetails: ProjectDetails): Promise<void> {
        return this.apiService.delete(`/project/${projectDetails.id}/close`);
    }

    public toggleOnHold(projectDetails: ProjectDetails): Promise<ProjectDetails> {
        return this.apiService.put(`/project/${projectDetails.id}/on_hold`, projectDetails.toApiModel())
            .then((projectResponse: HttpResponse<IProjectDetailsData>) => {
                return new ProjectDetails(projectResponse.body);
            });
    }
}
