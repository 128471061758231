import {Component, OnInit} from '@angular/core';
import {CompanyService, LoadingSpinnerService, ToastService, UserService} from '../../services';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {TranslateService} from '@ngx-translate/core';
import {ICompany} from '../../models';
import {CreateUpdateCompanyModalComponent, DeleteItemModalComponent} from '../../components';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-page-companies',
    templateUrl: './page-companies.component.html',
    styleUrls: ['./page-companies.component.scss']
})
export class PageCompaniesComponent implements OnInit {
    public companies: ICompany[];

    constructor(
        private userService: UserService,
        private companyService: CompanyService,
        private modalService: VwuiModalService,
        private toastService: ToastService,
        private translateService: TranslateService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    ngOnInit(): void {
        this.loadCompanies();
    }

    public openUpdateCompanyModal(company: ICompany): void {
        this.openCreateOrUpdateModal(company);
    }

    public openCreateCompanyModal(): void {
        this.openCreateOrUpdateModal();
    }

    public openDeleteCompanyModal(company: ICompany): void {
        if (this.isDeleteDisabled()) {
            return;
        }
        const modal = this.modalService.open(DeleteItemModalComponent,
            {
                data: {
                    title: `PAGE_COMPANIES.delete_item.title`,
                    description: `PAGE_COMPANIES.delete_item.description`
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                this.companyService.deleteItem(company).then(() => {
                    this.toastService.openSuccess(this.translateService.instant('PAGE_COMPANIES.company_removed'));
                    this.loadCompanies();
                }).catch((error) => {
                    if (error instanceof HttpErrorResponse && error.status === 400) {
                        this.toastService.handleHttpError(error);
                    } else {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.company_not_deleted'));
                    }
                });
            }

        });
    }

    public deleteDisabledReason(): string {
        if (this.isDeleteDisabled()) {
            if (!this.userService.isAdmin() || !this.userService.isRiskManager()) {
                return this.translateService.instant('DISABLED.not_admin');
            }
        }
        return undefined;
    }

    private loadCompanies() {
        this.loadingSpinnerService.show();
        this.companyService.getCompanies()
            .then((companies) => {
                this.companies = companies;

                // Reset the user info to make sure the companies are kept in sync
                this.userService.resetUserInfo();
            }).catch((error) => {
            this.toastService.openError(error.status, this.translateService.instant('ERRORS.companies_not_retrieved'));
        }).finally(() => {
            this.loadingSpinnerService.hide();
        });

    }

    private openCreateOrUpdateModal(company?: ICompany): void {
        const modal = this.modalService.open(CreateUpdateCompanyModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'medium-modal',
                data: {
                    title: company ? 'PAGE_COMPANIES.update_item.title' : `PAGE_COMPANIES.create_item.title`,
                    saveText: company ? 'PAGE_COMPANIES.update_item.save' : `PAGE_COMPANIES.create_item.save`,
                    data: company
                }
            }
        );

        modal.afterClosed.subscribe((updatedItem: ICompany) => {
            if (updatedItem) {
                if (company !== undefined) {
                    this.companyService.updateCompany(updatedItem).then(() => {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_COMPANIES.company_modified'));
                        this.loadCompanies();
                    }).catch((error) => {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.company_not_modified'));
                    });
                } else if (this.isCompanyUnique(updatedItem.name)) {
                    this.companyService.createCompany(updatedItem).then(() => {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_COMPANIES.company_added'));
                        this.loadCompanies();
                    }).catch((error) => {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.company_not_added'));
                    });
                } else {
                    this.toastService.openError(403, this.translateService.instant('ERRORS.company_already_exists'));
                }
            }
        });
    }

    private isCompanyUnique(name: string): boolean {
        const uniqueName = name.toLowerCase().trim();

        return this.companies && this.companies.findIndex((company) => company.name.toLowerCase().trim() === uniqueName) === -1;
    }

    private isDeleteDisabled(): boolean {
        return !(this.userService.isAdmin() || this.userService.isRiskManager());
    }

}
