import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AutosizeModule} from 'ngx-autosize';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {TrackingModule} from '../services/tracking/tracking.module';
import {NgxFileDropModule} from 'ngx-file-drop';

import {
    ApiService,
    LibraryService,
    LoadingSpinnerService,
    ToastService,
    ProjectService,
    ProjectMandateService,
    QuickscanDefinitionService,
    StepService,
    TisDefinitionService,
    TranslationService,
    UserService,
    FilterService,
    PdfService,
    ImageService,
    CompanyTypeService,
    TenderManagerService,
    CompanyService,
    NgSelectService,
    NotificationService,
    AzureAdRegistrationService,
    UserSearchService
} from '../services';

import {TRANSLATION_NL} from '../translations';

import {
    PageLogoutComponent,
    PageNoAccessComponent,
    PageErrorComponent,
    PageOpportunitiesLibraryComponent,
    PageProjectsComponent,
    PageProjectStepsComponent,
    PageQuickscanComponent,
    PageQuickscansComponent,
    PageRisksLibraryComponent,
    PageStartComponent,
    PageTisComponent,
    PageTisversionComponent,
    PagePdfPortraitComponent,
    PagePdfLandscapeComponent,
    PageTenderManagersComponent,
    PageCompaniesComponent,
    PageCompanyTypesComponent,
    PageNotificationsComponent,
    PageUserManagementComponent
} from '../pages/';

import {AppComponent} from './app.component';

import {
    AddProjectLibraryItemsModalComponent,
    CreateProjectModalComponent,
    CreateUpdateLibraryCategoryModalComponent,
    CreateUpdateLibraryItemModalComponent,
    DeleteItemModalComponent,
    EditRemarkModalComponent,
    InlineNotificationComponent,
    ItemDescriptionTabComponent,
    ItemQuantificationTabComponent,
    ItemRiskMitigationTabComponent,
    LibraryMgmtComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerFullscreenComponent,
    ProgressBarComponent,
    ProjectDetailsComponent,
    ProjectQuickscanComponent,
    ProjectTisQuickscanComponent,
    QuickscanListComponent,
    ProjectQuickscanResultComponent,
    ProjectRisksComponent,
    ProjectOpportunitiesComponent,
    ProjectMandatingComponent,
    ProjectLibraryItemsComponent,
    PublishQuickScanVersionModalComponent,
    PublishTisModalComponent,
    QuickscanCreateUpdateCategoryModalComponent,
    QuickscanCreateUpdateQuestionModalComponent,
    ChartSignalMatrixComponent,
    BlockStatsComponent,
    BlockSignalComponent,
    StickyHeaderComponent,
    TisCreateUpdateCategoryModalComponent,
    TisCreateUpdateQuestionModalComponent,
    ScrollNavigationComponent,
    MenuComponent,
    NavItemComponent,
    ItemCardComponent,
    ProjectRiskRainbowComponent,
    ProjectRiskDossierComponent,
    ProjectTisComponent,
    QuestionAttachmentsComponent,
    QuestionInputComponent,
    QuantifySupplementProjectLibraryItemModalComponent,
    LibraryItemQuantificationGridComponent,
    ExplanationComponent,
    ItemStatusComponent,
    ShareProjectModalComponent,
    PdfPageComponent,
    PdfPagebreakComponent,
    PdfPageHeaderComponent,
    PdfPageFooterComponent,
    PdfPageProjectInfoComponent,
    PdfPageProjectTisComponent,
    DownloadPDFModalComponent,
    PdfPageQuickscanResultComponent,
    PdfPageQuickscanComponent,
    PdfPageTisQuickscanComponent,
    PdfPageQuickscanListComponent,
    PdfPageRiskRainbowComponent,
    PdfPageRisksAndOpportunitiesComponent,
    ScoreSignalBadgeComponent,
    CreateUpdateTenderManagerModalComponent,
    CreateUpdateCompanyModalComponent,
    CreateUpdateCompanyTypeModalComponent,
    ClassificationComponent,
    CloseProjectModalComponent,
    DeleteProjectModalComponent,
    ExecuteProjectModalComponent,
    PdfPageForecastEndResultListComponent,
    PdfPageForecastEndResultListTableComponent,
    PdfPageMandatingFlowComponent,
    PdfPageRisksAndOpportunitiesContentComponent,
    LibraryItemFiltersComponent,
    LibraryItemFiltersOptionsComponent,
    LibraryItemFiltersSelectedComponent,
    LibraryItemFiltersSelectedButtonComponent,
    LibraryItemFiltersWithToggleComponent,
    UpdateNotificationModalComponent,
    NotificationModalComponent,
    InputCounterComponent,
    VersionComponent,
    UserIdentityInputComponent,
    MandatingFlowInfoCardComponent,
    InputHintComponent,
    ChangeUserModalComponent,
    ProjectStatusComponent,
    ProjectOnHoldModalComponent,
    RadioGroupComponent
} from '../components';

import {
    CategoryGetAllSubCategoryQuestionsPipe,
    DateFormatPipe,
    SafeHTMLPipe,
    EscapeHTMLPipe,
    PriceFormatPipe,
    TrimPipe,
    NamesFormatPipe,
    IsValidEmailAddressPipe
} from '../pipes';

import {environment} from 'src/environments/environment';
import {ProjectStepGuard, UserGuard, UserRoleGuard} from '../guards';
import {VwuiAngularModule} from '@recognizebv/vwui-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import {FormatEuroCentsDirective} from '../directives/format-euro-cents.directive';
import {DisabledReasonDirective} from '../directives/disabled-reason.directive';
import {ClickOutsideDirective} from '../directives/click-outside.directive';
import {VwuiTableDefaultBreakpointDirective} from '../directives/vwui-table-default-breakpoint.directive';
import {MsAuthModule} from 'src/module/ms-auth';
import {RichTextEditorModule} from '../module/rich-text-editor/module';
import {UiAccessibilityModule} from '../ui-accessibility/src/public-api';

@NgModule({
    declarations: [
        FormatEuroCentsDirective,
        DisabledReasonDirective,
        ClickOutsideDirective,
        VwuiTableDefaultBreakpointDirective,
        AppComponent,
        InlineNotificationComponent,
        PageProjectStepsComponent,
        ProjectDetailsComponent,
        ProjectQuickscanComponent,
        ProjectTisQuickscanComponent,
        QuickscanListComponent,
        ProjectQuickscanResultComponent,
        ScrollNavigationComponent,
        ProjectRisksComponent,
        ProjectOpportunitiesComponent,
        ProjectLibraryItemsComponent,
        ProjectMandatingComponent,
        ProgressBarComponent,
        PageStartComponent,
        PageNoAccessComponent,
        PageErrorComponent,
        PageProjectsComponent,
        PageLogoutComponent,
        PageNotificationsComponent,
        LibraryMgmtComponent,
        LoadingSpinnerComponent,
        LoadingSpinnerFullscreenComponent,
        AddProjectLibraryItemsModalComponent,
        CreateUpdateLibraryCategoryModalComponent,
        CreateUpdateLibraryItemModalComponent,
        DeleteItemModalComponent,
        CreateProjectModalComponent,
        EditRemarkModalComponent,
        DateFormatPipe,
        PageQuickscansComponent,
        PageRisksLibraryComponent,
        PageOpportunitiesLibraryComponent,
        PageTisComponent,
        PageTisversionComponent,
        PublishQuickScanVersionModalComponent,
        PublishTisModalComponent,
        PageQuickscanComponent,
        QuickscanCreateUpdateCategoryModalComponent,
        ItemDescriptionTabComponent,
        ItemQuantificationTabComponent,
        ItemRiskMitigationTabComponent,
        QuickscanCreateUpdateQuestionModalComponent,
        TisCreateUpdateCategoryModalComponent,
        TisCreateUpdateQuestionModalComponent,
        ChartSignalMatrixComponent,
        BlockStatsComponent,
        BlockSignalComponent,
        StickyHeaderComponent,
        PagePdfPortraitComponent,
        PagePdfLandscapeComponent,
        PageTenderManagersComponent,
        PageCompaniesComponent,
        PageCompanyTypesComponent,
        SafeHTMLPipe,
        EscapeHTMLPipe,
        MenuComponent,
        NavItemComponent,
        ItemCardComponent,
        ProjectRiskRainbowComponent,
        ProjectRiskDossierComponent,
        ProjectTisComponent,
        QuestionAttachmentsComponent,
        QuestionInputComponent,
        QuantifySupplementProjectLibraryItemModalComponent,
        LibraryItemQuantificationGridComponent,
        ExplanationComponent,
        ItemStatusComponent,
        ShareProjectModalComponent,
        PdfPageComponent,
        PdfPagebreakComponent,
        PdfPageHeaderComponent,
        PriceFormatPipe,
        PdfPageProjectInfoComponent,
        PdfPageProjectTisComponent,
        DownloadPDFModalComponent,
        PdfPageQuickscanResultComponent,
        PdfPageQuickscanComponent,
        PdfPageTisQuickscanComponent,
        PdfPageQuickscanListComponent,
        PdfPageRiskRainbowComponent,
        PdfPageRisksAndOpportunitiesComponent,
        ScoreSignalBadgeComponent,
        CreateUpdateTenderManagerModalComponent,
        CreateUpdateCompanyModalComponent,
        CreateUpdateCompanyTypeModalComponent,
        PdfPageFooterComponent,
        ClassificationComponent,
        CloseProjectModalComponent,
        DeleteProjectModalComponent,
        ExecuteProjectModalComponent,
        PdfPageForecastEndResultListComponent,
        PdfPageForecastEndResultListTableComponent,
        PdfPageMandatingFlowComponent,
        PdfPageRisksAndOpportunitiesContentComponent,
        LibraryItemFiltersComponent,
        LibraryItemFiltersOptionsComponent,
        LibraryItemFiltersSelectedComponent,
        LibraryItemFiltersSelectedButtonComponent,
        LibraryItemFiltersWithToggleComponent,
        UpdateNotificationModalComponent,
        NotificationModalComponent,
        InputCounterComponent,
        TrimPipe,
        VersionComponent,
        CategoryGetAllSubCategoryQuestionsPipe,
        NamesFormatPipe,
        UserIdentityInputComponent,
        MandatingFlowInfoCardComponent,
        InputHintComponent,
        PageUserManagementComponent,
        ChangeUserModalComponent,
        IsValidEmailAddressPipe,
        ProjectStatusComponent,
        ProjectOnHoldModalComponent,
        RadioGroupComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MsAuthModule.forRoot(environment.msAuth),
        AppRoutingModule,
        TranslateModule.forRoot(),
        ToastrModule.forRoot(),
        NgxWebstorageModule.forRoot({
            prefix: 'RISKMONITOR',
            separator: '_',
            caseSensitive: true
        }),
        HttpClientModule,
        VwuiAngularModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        AutosizeModule,
        Nl2BrPipeModule,
        TrackingModule,
        NgxFileDropModule,
        CommonModule,
        RichTextEditorModule,
        UiAccessibilityModule.forRoot()
    ],
    entryComponents: [
        AddProjectLibraryItemsModalComponent,
        CreateUpdateLibraryCategoryModalComponent,
        CreateUpdateLibraryItemModalComponent,
        EditRemarkModalComponent,
        DeleteItemModalComponent,
        CreateProjectModalComponent,
        PublishQuickScanVersionModalComponent,
        PublishTisModalComponent,
        QuickscanCreateUpdateCategoryModalComponent,
        QuickscanCreateUpdateQuestionModalComponent,
        TisCreateUpdateCategoryModalComponent,
        TisCreateUpdateQuestionModalComponent,
        QuantifySupplementProjectLibraryItemModalComponent,
        ShareProjectModalComponent,
        DownloadPDFModalComponent,
        CreateUpdateTenderManagerModalComponent,
        CreateUpdateCompanyModalComponent,
        CreateUpdateCompanyTypeModalComponent,
        CloseProjectModalComponent,
        DeleteProjectModalComponent,
        ExecuteProjectModalComponent,
        UpdateNotificationModalComponent,
        NotificationModalComponent,
        ChangeUserModalComponent
    ],
    providers: [
        ApiService,
        LibraryService,
        LoadingSpinnerService,
        ToastService,
        ProjectService,
        ProjectMandateService,
        ImageService,
        QuickscanDefinitionService,
        TisDefinitionService,
        TranslationService,
        FilterService,
        UserService,
        StepService,
        DatePipe,
        UserGuard,
        UserRoleGuard,
        ProjectStepGuard,
        PdfService,
        CompanyTypeService,
        TenderManagerService,
        CompanyService,
        NgSelectService,
        NotificationService,
        AzureAdRegistrationService,
        UserSearchService
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

    public static injector: Injector;

    constructor(
        injector: Injector,
        private translationService: TranslationService,
        private ngSelectService: NgSelectService
    ) {
        AppModule.injector = injector;

        translationService.add({
            nl: TRANSLATION_NL
        });
        translationService.set('nl');
        translationService.addCountries();
        ngSelectService.init();
    }

}
