import {Component, EventEmitter, Input, Output} from '@angular/core';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ProjectService, QuickscanDefinitionService} from '../../services';
import {Category, IQuestionData, ProjectDetails, QuickscanDefinition} from '../../models';
import {PossibleAnswer} from '../../models/definition/possibleanswer';
import {Question} from '../../models/definition/question';
import {EditRemarkModalComponent} from '..';
import {TranslateService} from '@ngx-translate/core';
import {QuickscanAnswers} from '../../models/project/quickscan-answer';
import {CategoryInfos} from '../../models/project/category-info';

@Component({
    selector: 'app-quickscan-list',
    templateUrl: './quickscan-list.component.html',
    styleUrls: ['./quickscan-list.component.scss']
})
export class QuickscanListComponent {

    @Input()
    public projectDetails: ProjectDetails;

    @Input()
    public quickscanDefinition: QuickscanDefinition;
    @Input()
    public quickscanAnswers: QuickscanAnswers;
    @Input()
    public categoryInfo: CategoryInfos;
    @Input()
    public disabled: boolean;
    @Input()
    public tis = false;

    @Output() projectDetailsChanged = new EventEmitter<ProjectDetails>();

    constructor(
        private projectService: ProjectService,
        private quickscanDefinitionService: QuickscanDefinitionService,
        private modalService: VwuiModalService,
        private translateService: TranslateService
    ) {
    }

    public doChange(target: EventTarget, question: IQuestionData) {
        this.quickscanAnswers.getQuickscanAnswerForQuestion(question).setRemarks((target as HTMLInputElement).value);
        this.projectDetailsChanged.emit(this.projectDetails);
    }

    public answerChanged(selectedAnswer: PossibleAnswer, question: IQuestionData) {
        this.quickscanAnswers.updateQuickscanAnswer(question, selectedAnswer);
        this.projectDetailsChanged.emit(this.projectDetails);
    }

    public getWeightedScoreForQuestion(question: Question, category: Category) {
        const questionScore = this.getScoreForQuestion(question);
        if (questionScore > 0) {
            const maxScoreForCategory = category.getMaxScore();
            if (maxScoreForCategory !== 0) {
                return Math.round(questionScore * (category.weightedScore / category.getMaxScore()));
            }
        }
        return questionScore;
    }

    public getWeightedScoreForCategory(quickscanCategory: Category): number {
        const totalScore = quickscanCategory.getAllSubCategoryQuestions()
            .map((question) => this.getScoreForQuestion(question))
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);

        const maxScoreForCategory = quickscanCategory.getMaxScore();
        if (maxScoreForCategory !== 0) {
            return Math.round(totalScore * (quickscanCategory.weightedScore / quickscanCategory.getMaxScore()));
        }
    }

    public getRemarkForQuestion(question: Question): string {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);
        return quickscanAnswer.remarks;
    }

    public getIndicatorForCategory(quickscanCategory: Category): string {
        const answerScore = this.getWeightedScoreForCategory(quickscanCategory);
        const maxScore = quickscanCategory.weightedScore;

        // RISC-140 - Keep these calculates the same
        if ((answerScore / maxScore) > this.quickscanDefinition.redFlagTresholdCategory / 100) {
            return 'red';
        } else {
            return 'green';
        }
    }

    public getRedFlagsForCategory(quickscanCategory: Category) {
        let numberOfRedFlags = quickscanCategory.getAllSubCategoryQuestions()
            .map((question): number => this.hasRedFlagForQuestion(question) ? 1 : 0)
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);

        // RISC-140 - Added red flag indicator for category as well
        numberOfRedFlags += this.getIndicatorForCategory(quickscanCategory) === 'red' ? 1 : 0;

        return numberOfRedFlags + ' ' + this.translateService.instant('PAGE_FILL_QUICKSCAN.red_flags');
    }

    public addCategoryRemark(category: Category) {
        const currentRemark = this.categoryInfo.getRemarkForCategory(category);
        const modal = this.modalService.open(
            EditRemarkModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    remark: currentRemark,
                    readonly: this.disabled,
                    saveText: currentRemark !== undefined ? 'BUTTON.update' : 'BUTTON.create'
                }
            }
        );

        modal.afterClosed.subscribe((data: { remark: string }) => {
            if (data === undefined) {
                return;
            }
            this.categoryInfo.setRemarkForCategory(category, data.remark);
            this.projectDetailsChanged.emit(this.projectDetails);
        });
    }

    public hasCategoryRemark(category: Category): boolean {
        const remark = this.categoryInfo.getRemarkForCategory(category);
        return remark !== undefined && remark !== null && remark.length > 0;
    }

    public getAnswerIdForQuestion(question: Question): number {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);
        if (quickscanAnswer.answerId == null) {
            return null;
        } else {
            const result = question.possibleAnswers.find((entry) => quickscanAnswer.answerId === entry.id);
            if (result) {
                return result.id;
            }
            return null;
        }
    }

    public getPageName(): string {
        return this.tis ? 'PAGE_FILL_TIS_QUICKSCAN' : 'PAGE_FILL_QUICKSCAN';
    }

    public trackQuickscanCategory(index: number, category: Category): string {
        return `${category.id}`;
    }

    public trackSubCategoryQuestion(index: number, question: Question): string {
        return `${question.getParent().id}_${question.id}`;
    }

    private hasRedFlagForQuestion(question: Question): boolean {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);
        if (quickscanAnswer.answerId !== undefined) {
            const selectedAnswer = question.possibleAnswers.find((possibleAnswer) => possibleAnswer.id === quickscanAnswer.answerId);

            if (selectedAnswer !== undefined) {
                return selectedAnswer.triggersRedFlag;
            }
        }
        return false;
    }

    private canHaveRedFlagForQuestion(question: Question): boolean {
        return question.possibleAnswers.filter((answer) => answer.triggersRedFlag).length > 0;
    }

    private getScoreForQuestion(question: Question) {
        const quickscanAnswer = this.quickscanAnswers.getQuickscanAnswerForQuestion(question);
        if (quickscanAnswer.answerId !== undefined) {
            const selectedAnswer = question.possibleAnswers.find((possibleAnswer) => possibleAnswer.id === quickscanAnswer.answerId);

            if (selectedAnswer !== undefined) {
                return selectedAnswer.score;
            }
        }
        return 0;
    }

}
