import {ProjectClassification, ProjectStatus} from '../../enums';
import {IProject, Member, ProjectMember} from './project';
import {QuickscanAnswers} from './quickscan-answer';
import {ILibraryItemFiltersData, ILibraryItemFiltersOptionsData, IQuestionData, RiskAndOpportunityFilters} from '..';
import {CategoryInfos} from './category-info';
import {IProjectInfo} from './project-info';
import {ProjectLibraryItem, ProjectLibraryItemStatus} from './project-libary-item';
import {IProjectDetailsData, ITisAnswerValue} from './project-data';
import {TisAnswer} from './tis-answer';
import {ObjectUtil} from '../../utils';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ILibraryItemSortOptionsData} from '../library/sort-data';
import {IUserIdentity} from '../user/user';

export class ProjectDetails implements IProject {
    id: number;
    name: string;
    owner: IUserIdentity;
    riskManager: IUserIdentity;
    newRiskManager: IUserIdentity;
    newOwner: IUserIdentity;
    description: string;
    status: ProjectStatus;
    statusBeforeOnHold: ProjectStatus;
    modifiedAt: Date;
    classification: ProjectClassification;
    tisClassification: ProjectClassification;
    tisClassificationOutdated: boolean;
    address: string;
    city: string;
    country: string;
    company: string;
    companyTypeId: number;
    quickscanDefinitionVersionId: number;
    tisDefinitionVersionId: number;
    thumbnailBase64: string;
    evaluation: string;
    statementFromUser: string;
    projectInfo: IProjectInfo;
    categoryInfo: CategoryInfos;
    tisCategoryInfo: CategoryInfos;
    quickscanAnswers: QuickscanAnswers;
    tisAnswers: TisAnswer[];
    tisQuickscanAnswers: QuickscanAnswers;
    projectLibraryItems: ProjectLibraryItem[];
    riskAndOpportunityFilters: RiskAndOpportunityFilters;
    libraryItemSort: ILibraryItemSortOptionsData;
    durationInMonths: number;
    riskAssessmentDescription: string;
    members: ProjectMember[];
    companyMembers: Member[];
    tisLabelShort: string;
    changed$ = new BehaviorSubject(false);

    constructor(data: IProjectDetailsData) {
        this.id = data.id;
        this.name = data.name;
        this.owner = data.owner;
        this.newOwner = data.owner;
        this.riskManager = data.riskManager;
        this.newRiskManager = data.riskManager;
        this.status = data.status;
        this.statusBeforeOnHold = data.statusBeforeOnHold;
        this.modifiedAt = new Date(data.modifiedAt);
        this.description = data.description;
        this.classification = data.classification;
        this.tisClassification = data.tisClassification;
        this.tisClassificationOutdated = data.tisClassificationOutdated;
        this.tisLabelShort = data.tisLabelShort;
        this.address = data.address;
        this.city = data.city;
        this.country = data.country;
        this.company = data.company;
        this.companyTypeId = data.companyTypeId;
        this.projectInfo = data.projectInfo;
        this.quickscanDefinitionVersionId = data.quickscanDefinitionVersionId;
        this.tisDefinitionVersionId = data.tisDefinitionVersionId;
        this.quickscanAnswers = new QuickscanAnswers(data.quickscanAnswers);
        this.tisAnswers = data.tisAnswers.map(answer => new TisAnswer(answer));
        this.tisQuickscanAnswers = new QuickscanAnswers(data.tisQuickscanAnswers);
        this.evaluation = data.evaluation;
        this.statementFromUser = data.statementFromUser;
        this.durationInMonths = data.durationInMonths;
        this.projectLibraryItems = data.projectLibraryItems.map(entry => new ProjectLibraryItem(entry));
        this.categoryInfo = new CategoryInfos(data.categoryInfo ? data.categoryInfo : []);
        this.tisCategoryInfo = new CategoryInfos(data.tisCategoryInfo ? data.categoryInfo : []);
        this.riskAndOpportunityFilters = new RiskAndOpportunityFilters(data.riskAndOpportunityFilters);
        this.libraryItemSort = data.libraryItemSort;
        this.riskAssessmentDescription = data.riskAssessmentDescription;
        this.members = data.members ? data.members.map((member) => new ProjectMember(member)) : [];
        this.companyMembers = data.companyMembers ? data.companyMembers.map((member) => new Member(member)) : [];
    }

    public getTisAnswerForQuestion(question: IQuestionData): TisAnswer {
        let tisAnswer = this.tisAnswers.find((answer) => answer.questionId === question.id);
        if (tisAnswer === undefined) {
            tisAnswer = new TisAnswer({questionId: question.id, value: {attachments: []}});
            this.tisAnswers.push(tisAnswer);
        }

        return tisAnswer;
    }

    public updateTisAnswer(question: IQuestionData, answer: ITisAnswerValue) {
        const tisAnswer = this.getTisAnswerForQuestion(question);
        tisAnswer.value.date = answer.date;
        tisAnswer.value.text = answer.text;
    }

    public getRisks(clientOnly = false): ProjectLibraryItem[] {
        return this.projectLibraryItems
            .filter((projectLibraryItem: ProjectLibraryItem) => projectLibraryItem.libraryType === 'risks')
            .filter((item) => clientOnly ? (item.riskClient && item.status !== ProjectLibraryItemStatus.PROPOSED): true);
    }

    public getRisksWithinFilters(filters: ILibraryItemFiltersData, clientOnly = false): ProjectLibraryItem[] {
        return this.getRisks(clientOnly)
            .filter((projectLibraryItem: ProjectLibraryItem) => filters === undefined || filters.riskScore <= projectLibraryItem.getScore())
            .filter((projectLibraryItem) => filters === undefined || this.withinGenericFilters(filters, projectLibraryItem));
    }

    public getOpportunities(clientOnly = false): ProjectLibraryItem[] {
        return this.projectLibraryItems
            .filter((projectLibraryItem: ProjectLibraryItem) => projectLibraryItem.libraryType === 'opportunities')
            .filter((item) => clientOnly ? (item.riskClient && item.status !== ProjectLibraryItemStatus.PROPOSED): true);
    }

    public getOpportunitiesWithinFilters(filters: ILibraryItemFiltersData, clientOnly = false): ProjectLibraryItem[] {
        return this.getOpportunities(clientOnly)
            .filter((projectLibraryItem: ProjectLibraryItem) => filters === undefined || filters.opportunityScore <= projectLibraryItem.getScore())
            .filter((projectLibraryItem) => filters === undefined || this.withinGenericFilters(filters, projectLibraryItem));
    }

    public isMandated(): boolean {
        switch (this.status) {
            case ProjectStatus.MANDATING:
            case ProjectStatus.MANDATING_SUCCEEDED:
            case ProjectStatus.IN_EXECUTION:
                return true;
            default:
                return false;
        }
    }

    public isOnHold(): boolean {
        return (this.status === ProjectStatus.ON_HOLD);
    }

    public isInMandateProcess(): boolean {
        return (this.status === ProjectStatus.MANDATING);
    }

    public isClosed(): boolean {
        return (this.status === ProjectStatus.CLOSED);
    }

    public hasAttachmentsForTis(): boolean {
        return (this.tisAnswers.some(answer => answer.value.attachments.length > 0));
    }

    public getDefaultLibraryItemProjectFilters(translateService: TranslateService): ILibraryItemFiltersData {
        const projectFilters = this.riskAndOpportunityFilters;
        return {
            riskScore: projectFilters.riskScore || 0,
            opportunityScore: projectFilters.opportunityScore || 0,
            sharedWithPrincipal: projectFilters.sharedWithPrincipal || false,
            includeProposals: projectFilters.includeProposals || false,
            selectedOnly: projectFilters.selectedOnly || false,
            selectedItems: this.getSelectedItemsProjectFiler(projectFilters),
            actionHolders: this.getActionHoldersProjectFilter(projectFilters),
            themes: this.getThemesProjectFilter(projectFilters),
            bestGuessMin: projectFilters.bestGuessMin,
            bestGuessMax: projectFilters.bestGuessMax,
            statuses: this.getStatusesProjectFilter(projectFilters, translateService)
        };
    }

    public getDefaultLibraryItemFilters(translateService: TranslateService): ILibraryItemFiltersData {
        return {
            riskScore: 0,
            opportunityScore: 0,
            sharedWithPrincipal: false,
            includeProposals: false,
            selectedOnly: false,
            selectedItems: this.getSelectedItems(),
            actionHolders: this.getActionHolders(),
            themes: this.getThemes(),
            bestGuessMin: null,
            bestGuessMax: null,
            statuses: this.getStatuses(translateService)
        };
    }

    toApiModel() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cloned: any = ObjectUtil.cloneObject(this);
        cloned.quickscanAnswers = this.quickscanAnswers.getQuickscanAnswers();
        cloned.tisQuickscanAnswers = this.tisQuickscanAnswers.getQuickscanAnswers();
        cloned.categoryInfo = this.categoryInfo.getCategoryInfo();
        cloned.tisCategoryInfo = this.tisCategoryInfo.getCategoryInfo();
        // RISC-760: clone missed the newRiskManager in some cases. Why?? Talk to the ObjectUtil.cloneObject() .....
        cloned.newRiskManager = this.newRiskManager;
        return cloned;
    }

    hasTisQuickscanBeenChanged() {
        return this.tisClassificationOutdated;
    }

    changed() {
        this.changed$.next(true);
    }

    onChanged() {
        return this.changed$;
    }

    private getRisksAndOpportunities(property: string) {
        const items = [...this.getRisks(), ...this.getOpportunities()]
            .map((item) => item[property])
            .filter((item) => !!item)
            .sort((a, b) => (a + '').localeCompare((b + '')));
        return [...new Set(items)];
    }

    private getSelectedItems() {
        const result = {};
        for (const id of this.getRisksAndOpportunities('id')) {
            result['item-' + id] = {name: id, selected: false};
        }
        return result;
    }

    private getSelectedItemsProjectFiler(projectFilters: RiskAndOpportunityFilters) {
        const result = {};
        for (const id of this.getRisksAndOpportunities('id')) {
            result['item-' + id] = {name: id, selected: projectFilters.selectedItems.includes(`${id}`)};
        }
        return result;
    }

    private getActionHolders() {
        const result = {};
        for (const uniqueActionHolder of this.getRisksAndOpportunities('counterMeasuresActionHolder')) {
            result[uniqueActionHolder] = {name: uniqueActionHolder, selected: false};
        }
        return result;
    }

    private getActionHoldersProjectFilter(projectFilters: RiskAndOpportunityFilters) {
        const result = {};
        for (const uniqueActionHolder of this.getRisksAndOpportunities('counterMeasuresActionHolder')) {
            result[uniqueActionHolder] = { name: uniqueActionHolder, selected: projectFilters.actionHolders.includes(uniqueActionHolder) };
        }
        return result;
    }

    private getThemes() {
        const result = {};
        for (const uniqueTheme of this.getRisksAndOpportunities('libraryCategoryName')) {
            const name = uniqueTheme;
            result[uniqueTheme] = { name, selected: false };
        }
        return result;
    }

    private getThemesProjectFilter(projectFilters: RiskAndOpportunityFilters) {
        const result = {};
        for (const uniqueTheme of this.getRisksAndOpportunities('libraryCategoryName')) {
            const name = uniqueTheme;
            result[uniqueTheme] = {name, selected: projectFilters.themes.includes(uniqueTheme)};
        }
        return result;
    }

    private getStatuses(translateService: TranslateService) {
        const result = {};
        for (const uniqueStatus of this.getRisksAndOpportunities('status')) {
            result[uniqueStatus] = {name: translateService.instant('PAGE_PROJECT_LIBRARY_ITEMS.library_type.risks.status.' + uniqueStatus), selected: false};
        }
        return result;
    }

    private getStatusesProjectFilter(projectFilters: RiskAndOpportunityFilters, translateService: TranslateService) {
        const result = {};
        for (const uniqueStatus of this.getRisksAndOpportunities('status')) {
            result[uniqueStatus] = {name: translateService.instant('PAGE_PROJECT_LIBRARY_ITEMS.library_type.risks.status.' + uniqueStatus), selected: projectFilters.statuses.includes(uniqueStatus)};
        }
        return result;
    }

    private withinGenericFilters(filters: ILibraryItemFiltersData, projectLibraryItem: ProjectLibraryItem) {
        const actionHolders = this.getSelected(filters.actionHolders);
        if (actionHolders.length > 0) {
            if (!actionHolders.includes(projectLibraryItem.getActionholder())) {
                return false;
            }
        }
        if (filters.bestGuessMin) {
            if (!projectLibraryItem.bestGuessCosts || filters.bestGuessMin > projectLibraryItem.bestGuessCosts) {
                return false;
            }
        }
        if (filters.bestGuessMax) {
            if (!projectLibraryItem.bestGuessCosts || filters.bestGuessMax < projectLibraryItem.bestGuessCosts) {
                return false;
            }
        }
        if (!filters.includeProposals && projectLibraryItem.status === ProjectLibraryItemStatus.PROPOSED) {
            return false;
        }

        const themes = this.getSelected(filters.themes);
        if (themes.length > 0) {
            const libraryCategoryName = projectLibraryItem.libraryCategoryName;
            if (!themes.includes(libraryCategoryName)) {
                return false;
            }
        }
        const statuses = this.getSelected(filters.statuses);
        if (statuses.length > 0) {
            if (!statuses.includes(projectLibraryItem.getStatus())) {
                return false;
            }
        }
        if (filters.sharedWithPrincipal) {
            if (filters.sharedWithPrincipal !== projectLibraryItem.riskClient) {
                return false;
            }
        }

        if (filters.selectedOnly) {
            const selectedItems = this.getSelectedName(filters.selectedItems);
            return selectedItems.includes(`${projectLibraryItem.id}`);
        }

        return true;
    }

    private getSelected(options: ILibraryItemFiltersOptionsData): string[] {
        return Object.entries(options)
            .filter((key, _) => key[1].selected)
            .map((key, _) => key[0]);
    }

    private getSelectedName(options: ILibraryItemFiltersOptionsData): string[] {
        return Object.entries(options)
            .filter((key, _) => key[1].selected)
            .map((key, _) => `${key[1].name}`);
    }

}
