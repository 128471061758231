export enum ProjectSteps {
    DETAILS = 1,
    QUICKSCAN = 2,
    RISKS = 3,
    OPPORTUNITIES = 4,
    DOSSIER = 5,
    RAINBOW = 6,
    TIS_QUICKSCAN = 7,
    TIS_QUESTIONS = 8,
    MANDATING = 9,
}
