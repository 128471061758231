import {CategoryType} from './category-type';
import {CategoryDefault} from './category-default';
import {ICategoryData} from './category-data';
import {ObjectUtil} from '../../utils';
import {Question} from './question';
import {IQuestionData} from './question-data';
import {TisDefinition} from '../index';
import {IDefinition} from './definition';

export class Category {

    public id: number;
    public name: string;
    public numberPrefix: string;
    public type: CategoryType;
    public weightedScore: number;
    public subCategories: Category[];
    public questions: Question[];

    constructor(
        config: ICategoryData,
        private parent: IDefinition | Category
    ) {
        this.setData(config, false);
    }

    public setData(data: ICategoryData, broadcast: boolean = true): void {
        const settings = ObjectUtil.mergeObjects<ICategoryData>([CategoryDefault, data]);

        this.id = settings.id;
        this.name = settings.name;
        this.numberPrefix = settings.numberPrefix;
        this.type = settings.type;
        this.weightedScore = settings.weightedScore;
        this.subCategories = settings.subCategories.map((category) => new Category(category, this));
        this.questions = settings.questions.map((question) => new Question(question, this));

        if (broadcast) {
            this.broadcastChanges();
        }
    }

    public broadcastChanges(): void {
        this.parent.broadcastChanges();
    }

    public getCategoryName(): string {
        return this.name;
    }

    public addQuestion(data: IQuestionData): Question {
        const question = new Question(data, this);
        this.questions.push(question);
        this.broadcastChanges();

        return question;
    }

    public addSubCategory(data: ICategoryData): Category {
        const subCategory = new Category(data, this);
        this.subCategories.push(subCategory);
        this.broadcastChanges();

        return subCategory;
    }

    public addSubCategoryQuestion(data: IQuestionData): Question {
        if (this.getDefinition() instanceof TisDefinition) {
            // no subcategories for TIS
            return this.addQuestion(data);
        }
        if (!this.hasSubCategories() || this.getLastSubCategory().name !== data.categoryName) {
            const id = this.getCategoryIdByName(data.categoryName);
            this.addSubCategory({id, name: data.categoryName, weightedScore: 0});
        }

        return this.getLastSubCategory().addQuestion(data);
    }

    public hasSubCategories(): boolean {
        return this.subCategories.length > 0;
    }

    public hasQuestions(): boolean {
        return this.questions.length > 0;
    }

    public getCategoryIdByName(categoryName: string): number | undefined {
        const category = this.getCategoryByName(categoryName);

        return category ? category.id : undefined;
    }

    public getCategoryByName(categoryName: string): Category | undefined {
        return this.getDefinition().getSubCategories().find((category) => category.name === categoryName);
    }

    public changeCategory(question: Question, categoryName: string) {
        const base = this.parent as Category;
        const allQuestions = base.getAllSubCategoryQuestions();
        const oldIndex = allQuestions.indexOf(question);

        const oldCategory = question.getParent();

        let newCategory;
        const newCategoryId = this.getCategoryIdByName(categoryName);

        if (newCategoryId) {
            newCategory = new Category({ id: newCategoryId, name: categoryName, questions: [question] }, this);
        } else {
            // create new category
            newCategory = new Category({ name: categoryName, questions: [question] }, this);
        }

        oldCategory.questions = oldCategory.questions.filter(oldCategoryQuestion => oldCategoryQuestion !== question);
        base.subCategories.push(newCategory);

        if (oldCategory.questions.length === 0) {
            base.subCategories = base.subCategories.filter(subCategory => subCategory !== oldCategory);
        }

        base.swapQuestions(oldIndex, base.getSubcategoryQuestionCount() - 1);
    }

    public getLastSubCategory(): Category | undefined {
        return this.hasSubCategories() ? this.subCategories[this.subCategories.length - 1] : undefined;
    }

    public getAllSubCategoryQuestions(): Question[] {
        const questions = [];

        this.questions.forEach((question) => {
            questions.push(question);
        });
        this.subCategories.forEach((category) => {
            category.questions.forEach((question) => {
                questions.push(question);
            });
        });

        return questions;
    }

    public getSubcategoryQuestionCount(): number {
        return this.subCategories.reduce((count, subCategory) => count + subCategory.questions.length, 0);
    }

    public getDefinition(): IDefinition {
        return this.parent.getDefinition();
    }

    public delete(): void {
        this.parent.deleteCategory(this);
    }

    public deleteCategory(category: Category): void {
        this.subCategories = this.subCategories.filter((subCategory) => subCategory !== category);
        this.broadcastChanges();
    }

    public deleteQuestion(question: Question): void {
        this.questions = this.questions.filter((questionInstance) => questionInstance !== question);
        if (!this.hasQuestions() && !this.hasSubCategories()) {
            this.delete();
        } else {
            this.broadcastChanges();
        }
    }

    public cloneData(): ICategoryData {
        return ObjectUtil.cloneObject(this);
    }

    public moveUp() {
        this.getDefinition().moveCategoryUp(this);
    }

    public moveDown() {
        this.getDefinition().moveCategoryDown(this);
    }

    public moveQuestionUp(question: Question) {
        const allQuestions = this.getAllSubCategoryQuestions();

        const index = ObjectUtil.findIndexForEqualObject<Question>(question, allQuestions);
        const newIndex = Math.max(0, index - 1);

        this.swapQuestions(index, newIndex);
    }

    public moveQuestionDown(question: Question) {
        const allQuestions = this.getAllSubCategoryQuestions();

        const index = allQuestions.findIndex(it => it === question || it.id === question.id);
        const newIndex = Math.min(allQuestions.length - 1, index + 1);

        this.swapQuestions(index, newIndex);
    }

    public getParent(): IDefinition | Category {
        return this.parent;
    }

    protected swapQuestions(index1: number, index2: number) {
        const allQuestions = this.getAllSubCategoryQuestions();

        const item1 = allQuestions[index1];
        const item2 = allQuestions[index2];

        allQuestions[index1] = item2;
        allQuestions[index2] = item1;

        // regroup questions
        const newSubCategories = [];
        let lastCategory = null;
        const newQuestions = [];

        for (const question of allQuestions) {
            const parent = question.getParent();
            if (parent instanceof Category) {
                let newSubCategory = newSubCategories[newSubCategories.length - 1];
                if (lastCategory !== parent) {
                    newSubCategory = parent.cloneData();
                    newSubCategory.questions = [];

                    newSubCategories.push(newSubCategory);
                }

                newSubCategory.questions.push(question.cloneData());

                lastCategory = parent;
            } else {
                newQuestions.push(question.cloneData());
            }
        }

        this.subCategories = newSubCategories.map((category) => new Category(category, this));
        this.questions = newQuestions.map((question) => new Question(question, this));
        this.broadcastChanges();
    }

    public getMaxScore(): number {
        return this.getAllSubCategoryQuestions()
            .map((question) => question.possibleAnswers.map(answer => answer.score).reduce((previous, current) => Math.max(current, previous), 0))
            .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);
    }
}
