import {Component} from '@angular/core';

declare global {
    interface Window {
        appVersion: string;
        appVersionLog: string;
    }
}

interface VersionLogEntry {
    date: string;
    subject: string;
}

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent {

    public appVersion: string = window.appVersion;
    public appVersionLog: VersionLogEntry[] = JSON.parse(atob(window.appVersionLog));

}
