import {IQuestionData} from './question-data';
import {IQuestionType} from './question-type';

export const QuestionDefault: IQuestionData = {
    id: undefined,
    name: '',
    info: '',
    type: IQuestionType.TEXT,
    allowAttachments: false,
    possibleAnswers: []
};
