import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ProjectDetails} from '../../models';
import {CompanyTypeService, ProjectService, ToastService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ObjectUtil} from '../../utils';
import {ICompanyType, IUserIdentity} from '../../models/user/user';
import {IProjectMember, ProjectMember} from '../../models/project/project';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-share-project-modal',
    templateUrl: './share-project-modal.component.html',
    styleUrls: ['./share-project-modal.component.scss']
})
export class ShareProjectModalComponent {

    public form = new FormGroup({
        user: new FormControl(null)
    });

    public saving = false;

    private members: IProjectMember[];
    public projectDetails: ProjectDetails;
    public companyType: ICompanyType = null;
    public identitiesToExclude$: BehaviorSubject<IUserIdentity[]> = new BehaviorSubject<IUserIdentity[]>([]);

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ projectDetails: ProjectDetails }>,
        private projectService: ProjectService,
        private toastService: ToastService,
        private companyTypeService: CompanyTypeService,
        private translateService: TranslateService
    ) {
        this.members = ObjectUtil.cloneObject(modalConfig.data.projectDetails.members);
        this.projectDetails = modalConfig.data.projectDetails;
        this.updateExclusionList();

        this.companyTypeService.getCompanyType(this.projectDetails.companyTypeId).then((companyType) => {
            this.companyType = companyType;
        });
    }

    public submit() {
        this.saving = true;
        this.projectDetails.members = this.members.map((iProjectMember) => new ProjectMember(iProjectMember));
        this.projectService.saveProjectDetails(this.projectDetails).then((newDetails) => {
            const message = this.translateService.instant('COMPONENT.share_project.succeeded');
            this.toastService.openSuccess(message);
            this.modalRef.close(newDetails);
        }).catch((error) => {
            if (error instanceof HttpErrorResponse && error.status === 400) {
                this.toastService.handleHttpError(error);
            } else {
                const errorMessage = this.translateService.instant('ERRORS.project_sharing_failed');
                this.toastService.openError(error.status, errorMessage);
            }
        }).finally(() => {
            this.saving = false;
        });
    }

    public getMembers(): IProjectMember[] {
        return this.members;
    }

    public removeMember(member: IProjectMember): void {
        const index = this.members.indexOf(member, 0);
        if (index > -1) {
            this.members.splice(index, 1);
        }
        this.updateExclusionList();
    }

    public addMember() {
        const user = this.form.value.user;
        if (this.form.valid && user) {
            this.members.push({
                user,
                quickscanEditor: false,
                projectLibraryItemEditor: false,
                tisEditor: false,
                hasViewRights: true
            });
            this.form.reset();
            this.updateExclusionList();
        }
    }

    public isAddMemberButtonDisabled(): boolean {
        return !(this.form.valid && this.form.value.user);
    }

    public toggleQuickscan($event: MouseEvent, member: IProjectMember) {
        $event.preventDefault();
        member.quickscanEditor = !member.quickscanEditor;
    }

    public toggleProjectLibraryItems($event: MouseEvent, member: IProjectMember) {
        $event.preventDefault();
        member.projectLibraryItemEditor = !member.projectLibraryItemEditor;
    }

    public toggleTis($event: MouseEvent, member: IProjectMember) {
        $event.preventDefault();
        member.tisEditor = !member.tisEditor;
    }

    private updateExclusionList(): void {
        const identitiesToExclude = [this.projectDetails.owner];
        this.members.forEach((member) => identitiesToExclude.push(member.user));
        this.identitiesToExclude$.next(identitiesToExclude);
    }
}
