import {ObjectUtil} from '../../utils';
import {IQuickscanDefinitionData} from './quickscandefinition-data';
import {QuickscanDefinitionDefault} from './quickscandefinition-default';
import {Category} from '../definition/category';
import {ICategoryData} from '../definition/category-data';
import {EventEmitter} from '@angular/core';
import {IDefinition} from '../definition/definition';
import {IScoreReferenceData} from '../quickscanresult/quickscanresult-data';

export class QuickscanDefinition implements IDefinition {

    public id: number;
    public versionId: number;
    public createdAt: Date;
    public isPublished: boolean;
    public publishedAt: Date;
    public redFlagTresholdCategory: number;
    public categories: Category[];
    public onChange: EventEmitter<IDefinition>;

    constructor(
        config: IQuickscanDefinitionData
    ) {
        const settings = ObjectUtil.mergeObjects<IQuickscanDefinitionData>([QuickscanDefinitionDefault, config]);

        this.id = settings.id;
        this.versionId = settings.versionId;
        this.createdAt = new Date(settings.createdAt);
        this.isPublished = settings.isPublished;
        this.publishedAt = new Date(settings.publishedAt);
        this.redFlagTresholdCategory = settings.redFlagTresholdCategory;
        this.categories = settings.categories.map((category) => new Category(category, this));

        this.onChange = new EventEmitter();
    }

    public broadcastChanges(): void {
        this.onChange.emit(this.getDefinition());
    }

    public getCategories(): Category[] {
        return this.categories;
    }

    public addCategory(data: ICategoryData): Category {
        const newCategory = new Category(data, this);
        this.categories.push(newCategory);
        this.broadcastChanges();

        return newCategory;
    }

    public getSubCategories(): Category[] {
        const subCategories = [];

        this.categories.forEach((category) => {
            category.subCategories.forEach((subCategory) => {
                subCategories.push(subCategory);
            });
        });

        return subCategories;
    }

    /**
     * Get all the unique subcategory names sorted ascending
     */
    public getSubCategoryNames(): string[] {
        return this.getSubCategories().map((subCategory) => subCategory.name).filter((value, index, self) => self.indexOf(value) === index).sort();
    }

    public getDefinition(): IDefinition {
        return this;
    }

    public deleteCategory(category: Category): void {
        this.categories = this.categories.filter((theme) => theme !== category);
        this.broadcastChanges();
    }

    public cloneData(): QuickscanDefinition {
        return ObjectUtil.cloneObject(this);
    }

    public moveCategoryUp(category: Category) {
        const index = ObjectUtil.findIndexForEqualObject(category, this.categories);
        const newIndex = Math.max(0, index - 1);

        this.swapCategories(index, newIndex);
    }

    public moveCategoryDown(category: Category) {
        const index = ObjectUtil.findIndexForEqualObject(category, this.categories);
        const newIndex = Math.min(this.categories.length - 1, index + 1);

        this.swapCategories(index, newIndex);
    }

    /**
     * Takes the red flag references and turns them into strings connected to the given category or questions
     */
    public getRedFlagStrings(redFlags: IScoreReferenceData[]): string[] {
        const redFlagStrings = [];
        redFlags.forEach((redFlag) => {
            let redFlagString = '';
            this.getCategories().forEach((category) => {
                if (redFlag.type === 'category' && category.id === redFlag.id) {
                    redFlagString = category.name;
                } else if (redFlag.type === 'answer') {
                    category.subCategories.forEach((subCategory) => {
                        subCategory.getAllSubCategoryQuestions().forEach((question) => {
                            question.possibleAnswers.forEach((possibleAnswer) => {
                                if (possibleAnswer.id === redFlag.id) {
                                    redFlagString = subCategory.name;
                                }
                            });
                        });
                    });
                }
            });

            redFlagStrings.push(redFlagString);
        });

        return redFlagStrings;
    }

    protected swapCategories(index1: number, index2: number) {
        const item1 = this.categories[index1];
        const item2 = this.categories[index2];

        this.categories[index1] = item2;
        this.categories[index2] = item1;
    }
}
