export {PageNoAccessComponent} from './no-access/page-no-access.component';
export {PageErrorComponent} from './error/page-error.component';
export {PageProjectsComponent} from './projects/page-projects.component';
export {PageStartComponent} from './start/page-start.component';
export {PageLogoutComponent} from './logout/page-logout.component';
export {PageQuickscansComponent} from './quickscans/page-quickscans.component';
export {PageProjectStepsComponent} from './project-steps/page-project-steps.component';
export {PageQuickscanComponent} from './quickscan/page-quickscan.component';
export {PageRisksLibraryComponent} from './riskslibrary/page-risks-library.component';
export {PageOpportunitiesLibraryComponent} from './opportunitieslibrary/page-opportunities-library.component';
export {PageTisComponent} from './tis/page-tis.component';
export {PageTisversionComponent} from './tisversion/page-tisversion.component';
export {PagePdfPortraitComponent} from './pdf-portrait/page-pdf-portrait.component';
export {PagePdfLandscapeComponent} from './pdf-landscape/page-pdf-landscape.component';
export {PageTenderManagersComponent} from './tender-managers/page-tender-managers.component';
export {PageCompaniesComponent} from './companies/page-companies.component';
export {PageCompanyTypesComponent} from './companytypes/page-company-types.component';
export {PageNotificationsComponent} from './notifications/page-notifications.component';
export {PageUserManagementComponent} from './user-management/page-user-management.component';
