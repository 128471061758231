import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Question} from '../../models/definition/question';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PossibleAnswer} from '../../models/definition/possibleanswer';
import {IQuestionType} from '../../models';
import moment from 'moment';
import {RouterService} from '../../services/router/router.service';

type QuestionValue = number | string | PossibleAnswer;
type ChangeFunction = (QuestionValueOutput) => void;

interface IVWUIInputvalueChanged {
    target: {
        value: string;
        valueAsDate?: Date;
    };
    stopPropagation: () => void;
}

@Component({
    selector: 'app-question-input',
    templateUrl: './question-input.component.html',
    styleUrls: ['./question-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionInputComponent),
            multi: true
        }
    ]
})
export class QuestionInputComponent implements OnInit, ControlValueAccessor {
    @Input() public question: Question;
    @Input() public readonly: boolean;
    @Output() public valueChanged: EventEmitter<QuestionValue> = new EventEmitter<QuestionValue>();

    public valueModel: QuestionValue;
    public QuestionType = IQuestionType;

    private onChangeFn: ChangeFunction;
    private onTouchedFn: ChangeFunction;

    public isPreviousUrlMandating: boolean;

    constructor(
        private routerService: RouterService
    ) {
    }

    ngOnInit() {
        this.isPreviousUrlMandating = this.routerService.checkIfFromMandating();
    }

    handleChange(newAnswer: QuestionValue | IVWUIInputvalueChanged) {
        const newAnswerEvent = newAnswer as IVWUIInputvalueChanged;

        let formattedAnswer;

        switch (this.question.type) {
            case IQuestionType.DATE:
                formattedAnswer = newAnswerEvent.target.value;

                formattedAnswer = moment(formattedAnswer).format('YYYY-MM-DD');
                newAnswerEvent.stopPropagation();

                this.writeValue(formattedAnswer === 'Invalid date' ? null : formattedAnswer);

                break;
            case IQuestionType.TEXT:
                formattedAnswer = newAnswerEvent.target.value;

                this.writeValue(formattedAnswer);
                newAnswerEvent.stopPropagation();

                break;
            default:
                formattedAnswer = newAnswer;
                break;
        }

        this.onTouchedFn(formattedAnswer);
        this.onChangeFn(formattedAnswer);

        this.valueChanged.emit(formattedAnswer);
    }

    registerOnChange(fn: ChangeFunction): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: ChangeFunction): void {
        this.onTouchedFn = fn;
    }

    writeValue(value: QuestionValue): void {
        this.valueModel = value;

        // For the read only version, keep the answer set
        if (this.readonly && this.question.type === IQuestionType.DATE) {
            const changedValue: string = this.valueModel as string;
            if (!!changedValue) {
                this.valueModel = moment(changedValue).format('DD-MM-YYYY');
            }
        }
    }

}
