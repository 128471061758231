import {PossibleAnswer} from './possibleanswer';
import {IQuestionData} from './question-data';
import {QuestionDefault} from './question-default';
import {ObjectUtil} from '../../utils';
import {Category} from './category';
import {IQuestionType} from './question-type';

export class Question {

    public id: number;
    public name: string;
    public info: string;
    public type: IQuestionType;
    public allowAttachments: boolean;
    public possibleAnswers: PossibleAnswer[];

    constructor(
        config: IQuestionData,
        private parent: Category
    ) {
        this.setData(config, false);
    }

    public setData(data: IQuestionData, broadcast: boolean = true): void {
        const settings = ObjectUtil.mergeObjects<IQuestionData>([QuestionDefault, data]);

        this.id = settings.id;
        this.name = settings.name;
        this.info = settings.info;
        this.allowAttachments = settings.allowAttachments;
        this.possibleAnswers = settings.possibleAnswers.map((possibleAnswer) => new PossibleAnswer(possibleAnswer));
        this.type = settings.type;

        if (settings.categoryName && settings.categoryName !== this.getCategoryName()) {
            this.parent.changeCategory(this, settings.categoryName);
        }

        if (broadcast) {
            this.broadcastChanges();
        }
    }

    public broadcastChanges(): void {
        this.parent.broadcastChanges();
    }

    public getCategoryName(): string {
        return this.parent.getCategoryName();
    }

    public getParent(): Category {
        return this.parent;
    }

    public delete(): void {
        this.parent.deleteQuestion(this);
    }

    public cloneData(): IQuestionData {
        return ObjectUtil.cloneObject(this);
    }

}
