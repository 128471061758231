import {Component, Input, OnInit} from '@angular/core';
import {
    LoadingSpinnerService,
    ProjectMandateService,
    ProjectService,
    QuickscanDefinitionService,
    TisDefinitionService
} from '../../services';
import {
    IQuestionData,
    IQuestionType,
    ProjectDetails,
    ProjectMandateDetails,
    QuickscanDefinition,
    TisDefinition
} from '../../models';
import {IScrollNavigationItem} from '../scroll-navigation/scroll-navigation.data';
import {IAttachment, ITisAnswerValue} from '../../models/project/project-data';
import {Question} from '../../models/definition/question';
import {RouterService} from '../../services/router/router.service';

@Component({
    selector: 'app-project-tis',
    templateUrl: './project-tis.component.html',
    styleUrls: ['./project-tis.component.scss']
})
export class ProjectTisComponent implements OnInit {

    public navItems: IScrollNavigationItem[] = [];
    public quickscanDefinition?: QuickscanDefinition;
    public tisDefinition?: TisDefinition;
    @Input() public projectDetails: ProjectDetails;
    public mandateDetails: ProjectMandateDetails;
    public isPreviousUrlMandating = false;

    constructor(
        private projectService: ProjectService,
        private quickscanDefinitionService: QuickscanDefinitionService,
        private tisDefinitionService: TisDefinitionService,
        private projectMandateService: ProjectMandateService,
        private routerService: RouterService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    async ngOnInit() {
        this.loadingSpinnerService.show();
        this.tisDefinition = await this.tisDefinitionService.getTisDefinition(
            this.projectDetails.companyTypeId,
            this.projectDetails.tisDefinitionVersionId,
            this.projectDetails.tisLabelShort
        );

        this.quickscanDefinition = await this.quickscanDefinitionService.getQuickscanDefinition(
            this.projectDetails.companyTypeId,
            this.projectDetails.quickscanDefinitionVersionId
        );

        this.navItems.push(
            ...this.tisDefinition.categories.map((category, index) => ({
                    label: '' + (index + 1) + '. ' + category.name,
                    elementId: 'ThemeTitleTIS' + category.id
                }))
        );

        await this.projectMandateService.getMandateDetails(this.projectDetails)
            .then((mandateDetails) => {
                this.mandateDetails = mandateDetails;
            });

        this.isPreviousUrlMandating = this.routerService.checkIfFromMandating();

        if (this.navItems && this.tisDefinition) {
            this.loadingSpinnerService.hide();
        }
    }

    public answerChanged(answer: string | Date, question: IQuestionData) {
        const tisAnswerValue = this.convertFormAnswerToRestTisValue(question, answer);

        this.projectDetails.updateTisAnswer(question, tisAnswerValue);
    }

    public attachmentsChanged(attachments: IAttachment[], question: IQuestionData) {
        const tisAnswerValue = this.projectDetails.getTisAnswerForQuestion(question).value;
        tisAnswerValue.attachments = attachments;

        this.projectDetails.updateTisAnswer(question, tisAnswerValue);
    }

    public getInitialValue(question: Question) {
        const answer = this.projectDetails.getTisAnswerForQuestion(question);

        return this.convertRestTisValueToFormAnswer(question, answer.value);
    }

    public getUriForStep(stepId: number): string {
        return `/project/${this.projectDetails.id}/step/${stepId}`;
    }

    public quickcanSubmitted(): boolean {
        return !(this.mandateDetails && this.mandateDetails.preconditions.quickscanNotSubmitted);
    }

    public canEditTis(): boolean {
        return !this.projectDetails.isMandated()
            && !this.projectDetails.isOnHold()
            && !this.projectDetails.isClosed()
            && !this.projectDetails.isInMandateProcess()
            && this.projectService.canEditTis(this.projectDetails);
    }

    public getQuestionAttachments(question: Question): IAttachment[] {
        const tisAnswerValue = this.projectDetails.getTisAnswerForQuestion(question).value;
        return tisAnswerValue.attachments;
    }

    public isQuestionEmpty(): boolean {
        let hasEmptyQuestion = false;
        this.tisDefinition.categories.map((category) => {
            category.questions.map((question) => {
                if (this.getInitialValue(question) == null || this.getInitialValue(question) === '') {
                    hasEmptyQuestion = true;
                }
            });
        });

        return hasEmptyQuestion;
    }

    private convertFormAnswerToRestTisValue(question: IQuestionData, answer: string | Date): ITisAnswerValue {
        if (question.type === IQuestionType.DATE) {
            let transformedValue = answer as Date;

            if (typeof transformedValue === 'string' && transformedValue === 'Invalid date') {
                transformedValue = null;
            }

            return {date: transformedValue, attachments: []};
        } else if (question.type === IQuestionType.TEXT) {
            const transformedValue = answer as string;

            return {text: transformedValue, attachments: []};
        }
    }

    private convertRestTisValueToFormAnswer(question: IQuestionData, restAnswer: ITisAnswerValue): string | Date {
        if (restAnswer === null) {
            return null;
        } else if (question.type === IQuestionType.DATE) {
            return restAnswer.date;
        } else if (question.type === IQuestionType.TEXT) {
            return restAnswer.text;
        }
    }
}
