import {Injectable} from '@angular/core';
import {UserService} from '..';
import {UserRoles} from '../../enums';
import {NavElement, NavItem} from '@recognizebv/vwui-angular';
import {CompanyType} from '../../models/user/user';

@Injectable({
    providedIn: 'root'
})
export class MenuBuilder {
    constructor(private userService: UserService) {
    }

    public async buildProjectsMenu(): Promise<NavElement[]> {
        if (await this.userService.userHasAnyRole([
            UserRoles.TENDER_MANAGER,
            UserRoles.COMPANY_PROJECT_EDITOR,
            UserRoles.COMPANY_PROJECT_VIEWER,
            UserRoles.COMPANY_ADMIN,
            UserRoles.PROJECT_EDITOR,
            UserRoles.PROJECT_VIEWER,
            UserRoles.RISK_MANAGER
        ])) {
            return [
                {
                    type: 'item',
                    label: 'MENU.labels.projects',
                    iconName: 'vwui-folder',
                    href: 'projecten'
                }
            ];

        } else {
            return [];
        }
    }

    public async buildAdminMenu(activecompanyType: CompanyType): Promise<NavElement[]> {
        const menu = [];

        if (await this.userService.userHasAnyRole([UserRoles.RISK_MANAGER])) {
            this.addRiskManagerMenu(menu, activecompanyType);
        }

        if (await this.userService.userHasAnyRole([UserRoles.RISK_MANAGER, UserRoles.ADMIN, UserRoles.COMPANY_ADMIN])) {
            this.addAdminMenu(menu);
        }

        return menu;
    }

    private addRiskManagerMenu(base: NavElement[], activeCompanyType?: CompanyType): void {
        const activeCompanyTypeId = activeCompanyType ? activeCompanyType.id : 0;
        base.push(
            {
                type: 'item',
                label: 'MENU.labels.quickscan_versions',
                iconName: 'vwui-layers',
                href: `beheer/quickscan/${activeCompanyTypeId}`
            },
            {
                type: 'item',
                label: activeCompanyType ? activeCompanyType.tisLabelShort : 'MENU.labels.tis_versions',
                iconName: 'vwui-layers',
                href: `beheer/tis/${activeCompanyTypeId}`
            },
            {
                type: 'item',
                label: 'MENU.labels.risk_library',
                iconName: 'vwui-cloud',
                href: `beheer/risicos/${activeCompanyTypeId}`
            },
            {
                type: 'item',
                label: 'MENU.labels.opportunity_library',
                iconName: 'vwui-cloud',
                href: `beheer/kansen/${activeCompanyTypeId}`
            }
        );
    }

    private async addAdminMenu(base: NavElement[]) {
        const hasFullAdminAccess = await this.userService.userHasAnyRole([UserRoles.ADMIN]);
        const isRiskManager = await this.userService.userHasAnyRole([UserRoles.RISK_MANAGER]);
        const menuItemsForAdminAndRiskManagerAndCompanyAdmin: NavItem[] = [
            {
                type: 'item',
                label: 'MENU.labels.company_mgmt',
                iconName: 'vwui-layers',
                href: 'beheer/werkmaatschappijen'
            },
            {
                type: 'item',
                label: 'MENU.labels.tender_manager_mgmt',
                iconName: 'vwui-layers',
                href: 'beheer/tendermanagers'
            }
        ];
        const menuItemsForRiskManager: NavItem[] = [
            {
                type: 'item',
                label: 'MENU.labels.user_mgmt',
                iconName: 'vwui-layers',
                href: 'beheer/gebruikersbeheer'
            }
        ];
        const menuItemsForAdminOnly: NavItem[] = [
            {
                type: 'item',
                label: 'MENU.labels.notifications',
                iconName: 'info',
                href: 'beheer/meldingen'
            },
            {
                type: 'item',
                label: 'MENU.labels.company_type_mgmt',
                iconName: 'vwui-layers',
                href: 'beheer/bedrijfstypes'
            }
        ];

        let menuItems = hasFullAdminAccess
            ? [...menuItemsForAdminOnly, ...menuItemsForAdminAndRiskManagerAndCompanyAdmin]
            : menuItemsForAdminAndRiskManagerAndCompanyAdmin;
        if (isRiskManager) {
            menuItems = [...menuItems, ...menuItemsForRiskManager];
        }

        base.push({
            type: 'header',
            label: 'MENU.labels.management_users',
            items: menuItems
        });
    }
}
