import {Component, OnInit} from '@angular/core';
import {CompanyTypeService, LoadingSpinnerService, ToastService, UserService} from '../../services';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {TranslateService} from '@ngx-translate/core';
import {CreateUpdateCompanyTypeModalComponent, DeleteItemModalComponent} from '../../components';
import {HttpErrorResponse} from '@angular/common/http';
import {ICompanyType} from '../../models/user/user';

@Component({
    selector: 'app-page-companies',
    templateUrl: './page-company-types.component.html',
    styleUrls: ['./page-company-types.component.scss']
})
export class PageCompanyTypesComponent implements OnInit {
    public companyTypes: ICompanyType[];

    constructor(
        private companyTypeService: CompanyTypeService,
        private modalService: VwuiModalService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private userService: UserService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    ngOnInit(): void {
        this.loadCompanyTypes();
    }

    public openUpdateCompanyTypeModal(companyType: ICompanyType): void {
        this.openCreateOrUpdateModal(companyType);
    }

    public openCreateCompanyTypeModal(): void {
        this.openCreateOrUpdateModal();
    }

    public openDeleteCompanyTypeModal(companyType: ICompanyType): void {
        const modal = this.modalService.open(DeleteItemModalComponent,
            {
                data: {
                    title: `PAGE_COMPANY_TYPES.delete_item.title`,
                    description: `PAGE_COMPANY_TYPES.delete_item.description`
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                this.companyTypeService.deleteItem(companyType).then(() => {
                    this.toastService.openSuccess(this.translateService.instant('PAGE_COMPANY_TYPES.type_removed'));
                    this.userService.resetUserInfo();
                    this.loadCompanyTypes();
                }).catch((error) => {
                    if (error instanceof HttpErrorResponse && error.status === 400) {
                        this.toastService.handleHttpError(error);
                    } else {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.type_not_deleted'));
                    }
                });
            }
        });
    }

    private loadCompanyTypes() {
        this.loadingSpinnerService.show();
        this.companyTypeService.getCompanyTypes()
            .then((companyTypes) => {
                this.companyTypes = companyTypes;
            }).catch((error) => {
            this.toastService.openError(error.status, this.translateService.instant('ERRORS.types_not_retrieved'));
        }).finally(() => {
            this.loadingSpinnerService.hide();
        });
    }

    private openCreateOrUpdateModal(companyType?: ICompanyType): void {
        const modal = this.modalService.open(CreateUpdateCompanyTypeModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: companyType ? 'PAGE_COMPANY_TYPES.update_item.title' : `PAGE_COMPANY_TYPES.create_item.title`,
                    saveText: companyType ? 'PAGE_COMPANY_TYPES.update_item.save' : `PAGE_COMPANY_TYPES.create_item.save`,
                    data: companyType
                },
                modalClass: 'modal-full-width'
            }
        );

        modal.afterClosed.subscribe((updatedItem: { companyType: ICompanyType; selectedBaseCompanyTypeId?: number }) => {
            if (updatedItem) {
                if (companyType !== undefined) {
                    this.companyTypeService.updateCompanyType(updatedItem.companyType).then(() => {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_COMPANY_TYPES.type_modified'));
                        this.userService.resetUserInfo();
                        this.loadCompanyTypes();
                    }).catch((error) => {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.type_not_modified'));
                    });
                } else if (this.isTypeUnique(updatedItem.companyType.name)) {
                    this.companyTypeService.createCompanyType(updatedItem.companyType, updatedItem.selectedBaseCompanyTypeId).then(() => {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_COMPANY_TYPES.type_added'));
                        this.userService.resetUserInfo();
                        this.loadCompanyTypes();
                    }).catch((error) => {
                        this.toastService.openError(error.status, this.translateService.instant('ERRORS.type_not_added'));
                    });
                } else {
                    this.toastService.openError(403, this.translateService.instant('ERRORS.type_already_exists'));
                }
            }
        });
    }

    private isTypeUnique(name: string): boolean {
        const uniqueName = name.toLowerCase().trim();

        return this.companyTypes && this.companyTypes.findIndex((type) => type.name.toLowerCase().trim() === uniqueName) === -1;
    }

}
