import {IProjectLibraryItemData, IQuantification, IRemark} from './project-data';
import {IUserIdentity} from '../user/user';

export enum ProjectLibraryItemStatus {
    PROPOSED = 'proposed',
    NEW = 'new',
    CURRENT = 'current',
    SETTLED = 'settled',
    INCIDENT = 'incident',
}

export const ProjectLibraryItemDefault: IProjectLibraryItemData = {
    id: undefined,
    status: ProjectLibraryItemStatus.PROPOSED,
    includedInPer: false,
    libraryItemId: -1,
    libraryCategoryId: undefined,
    libraryCategoryName: '',
    libraryCategoryPrefix: '',
    entryNumber: '',
    libraryType: '',
    subject: '',
    itemOwner: undefined,
    createdBy: undefined,
    minCosts: undefined,
    bestGuessCosts: undefined,
    maxCosts: undefined,
    riskDescription: '',
    riskClient: false,
    causeDescription: '',
    effectDescription: '',
    effectCategoryMoney: false,
    effectCategoryTime: false,
    effectCategoryQuality: false,
    effectCategorySafety: false,
    effectCategorySurroundings: false,
    effectCategoryImage: false,
    effectCategoryReputation: false,
    relationOtherRisks: '',
    possibleCounterMeasures: '',
    counterMeasuresActionHolder: '',
    counterMeasures: '',
    counterMeasuresCosts: 0,
    counterMeasuresDeadline: undefined,
    newRemark: '',
    remarks: [],
    quantification: undefined
};

class Remark implements IRemark {
    id: number;
    user: IUserIdentity;
    createdAt: Date;
    remark: string;

    constructor(entry: IRemark) {
        this.id = entry.id;
        this.user = entry.user;
        this.createdAt = new Date(entry.createdAt);
        this.remark = entry.remark;
    }
}

export class ProjectLibraryItem {
    id: number;
    libraryType: string;
    isProjectSpecific: boolean;
    status: ProjectLibraryItemStatus;
    includedInPer: boolean;
    libraryItemId?: number;
    libraryCategoryId: number;
    libraryCategoryName: string;
    libraryCategoryPrefix: string;
    entryNumber: string;
    subject: string;
    itemOwner: string;
    createdBy: string;
    minCosts?: number;
    bestGuessCosts?: number;
    maxCosts?: number;
    perCosts?: number;
    riskDescription: string;
    riskClient: boolean;
    causeDescription: string;
    effectDescription: string;
    effectCategoryMoney?: boolean;
    effectCategoryTime?: boolean;
    effectCategoryQuality?: boolean;
    effectCategorySafety?: boolean;
    effectCategorySurroundings?: boolean;
    effectCategoryImage?: boolean;
    effectCategoryReputation?: boolean;
    relationOtherRisks: string;
    possibleCounterMeasures: string;
    counterMeasuresActionHolder: string;
    counterMeasures: string;
    counterMeasuresCosts: number;
    counterMeasuresDeadline: string;
    newRemark: string;
    remarks: Remark[];
    quantification: IQuantification;
    libraryItemParentId?: number;

    constructor(data: IProjectLibraryItemData) {
        this.id = data.id;
        this.libraryType = data.libraryType;
        this.status = data.status;
        this.includedInPer = data.includedInPer;
        this.libraryItemId = data.libraryItemId;
        this.libraryCategoryId = data.libraryCategoryId;
        this.libraryCategoryName = data.libraryCategoryName;
        this.libraryCategoryPrefix = data.libraryCategoryPrefix;
        this.entryNumber = data.entryNumber;
        this.isProjectSpecific = !data.libraryItemId;
        this.subject = data.subject;
        this.itemOwner = data.itemOwner;
        this.createdBy = data.createdBy;
        this.minCosts = data.minCosts;
        this.bestGuessCosts = data.bestGuessCosts;
        this.maxCosts = data.maxCosts;
        this.perCosts = data.perCosts;
        this.riskDescription = data.riskDescription;
        this.riskClient = data.riskClient;
        this.causeDescription = data.causeDescription;
        this.effectDescription = data.effectDescription;
        this.effectCategoryMoney = data.effectCategoryMoney;
        this.effectCategoryTime = data.effectCategoryTime;
        this.effectCategoryQuality = data.effectCategoryQuality;
        this.effectCategorySafety = data.effectCategorySafety;
        this.effectCategorySurroundings = data.effectCategorySurroundings;
        this.effectCategoryImage = data.effectCategoryImage;
        this.effectCategoryReputation = data.effectCategoryReputation;
        this.relationOtherRisks = data.relationOtherRisks;
        this.possibleCounterMeasures = data.possibleCounterMeasures;
        this.counterMeasuresActionHolder = data.counterMeasuresActionHolder;
        this.counterMeasures = data.counterMeasures;
        this.counterMeasuresCosts = data.counterMeasuresCosts;
        this.counterMeasuresDeadline = data.counterMeasuresDeadline;
        this.newRemark = data.newRemark;
        this.remarks = data.remarks ? data.remarks.map(entry => new Remark(entry)) : [];
        this.quantification = data.quantification;
        this.libraryItemParentId = data.libraryItemParentId;
    }

    public getTitle(items: ProjectLibraryItem[]): string {
        return `${this.getEntryNumber(items)} ${this.subject}`;
    }

    public getSubject(): string {
        return this.subject;
    }

    public getEntryNumber(items: ProjectLibraryItem[]): string {
        let duplicateNumberPrefix = '';
        if (this.hasDuplicateItems(items)) {
            duplicateNumberPrefix = `.${this.getPostfixNumber(items)}`;
        }

        return (this.libraryCategoryPrefix ? `${this.libraryCategoryPrefix}.` : '') + this.entryNumber + duplicateNumberPrefix;
    }

    public getStatus(): string {
        return this.status;
    }

    public getScore(): number {
        const quantification = this.quantification;
        if (!quantification || quantification.probability === undefined) {
            return null;
        }
        const nrFilledQuantifications = Object.keys(quantification)
            .filter(category => category !== 'probability')
            .filter(category => quantification[category] !== null)
            .length;
        if (nrFilledQuantifications === 0) {
            return null;
        }

        /**
         * Waarschijnlijkheid * hoogst geselecteerde score * 4  (max = 100)
         */
        return Math.min(100, quantification.probability * 4 * Math.max(...
            Object.keys(quantification)
                .filter(category => category !== 'probability')
                .filter(category => quantification[category] !== null)
                .map(category => quantification[category])
        ));
    }

    public getActionholder(): string {
        return this.counterMeasuresActionHolder;
    }

    public getPostfixForItem(allItems: ProjectLibraryItem[]) {
        const totalNumberOfItemsForSameLibraryItem: number =
            allItems
                .filter((libraryItem) => libraryItem.libraryItemId === this.libraryItemId)
                .length;
        if (totalNumberOfItemsForSameLibraryItem > 1 && !this.isProjectSpecific) {
            const postFixNumber = this.getPostfixNumber(allItems);

            return ` (${postFixNumber} / ${totalNumberOfItemsForSameLibraryItem})`;
        }
        return '';
    }

    public getPostfixNumber(allItems: ProjectLibraryItem[]): number {
        let postfixNumber = 1;
        for (const libraryItem of allItems) {
            if (this.isSameItem(libraryItem)) {
                if (libraryItem.id < this.id) {
                    postfixNumber++;
                }
            }
        }

        return postfixNumber;
    }

    private hasDuplicateItems(items: ProjectLibraryItem[]) {
        return items
            .filter((libraryItem) => this.isSameItem(libraryItem))
            .length > 1;
    }

    private isSameItem(libraryItem: ProjectLibraryItem): boolean {
        if (this.isProjectSpecific !== libraryItem.isProjectSpecific) {
            return false;
        }
        if (this.isProjectSpecific) {
            return this.entryNumber === libraryItem.entryNumber;
        } else {
            return this.libraryItemId === libraryItem.libraryItemId;
        }
    }

    public getRemarks(clientOnly: boolean): Remark[] {
        if (!clientOnly) {
            return this.remarks;
        } else {
            // search string, as added in ProjectLibraryRestToOrm:;updateEntry
            return this.remarks.filter((remark) => !remark.remark.startsWith('Bedrag in PER is aangepast van '));
        }
    }

    public isFilledIn(): boolean {
        return this.isFullyDescribed()
            && this.isFullyQuantified()
            && this.hasEstimatedCostsForPer()
            && this.hasEstimatedCosts()
            && this.hasCountermeasures();
    }

    private isFullyDescribed(): boolean {
        return !!this.riskDescription
            && !!this.causeDescription
            && !!this.effectDescription
            && !!this.itemOwner;
    }

    private isFullyQuantified(): boolean {
        return this.isProbabilityQuantified()
            && this.isMoneyQuantified(this.effectCategoryMoney)
            && this.isTimeQuantified(this.effectCategoryTime)
            && this.isQualityQuantified(this.effectCategoryQuality)
            && this.isSafetyQuantified(this.effectCategorySafety)
            && this.isSurroundingsQuantified(this.effectCategorySurroundings)
            && this.isImageQuantified(this.effectCategoryImage)
            && this.isReputationQuantified(this.effectCategoryReputation);
    }

    private isProbabilityQuantified(): boolean {
        return typeof this.quantification?.probability === 'number';
    }

    private isMoneyQuantified(required: boolean): boolean {
        return required ? typeof this.quantification?.image === 'number' : true;
    }

    private isTimeQuantified(required: boolean): boolean {
        return required ? typeof this.quantification?.time === 'number' : true;
    }

    private isQualityQuantified(required: boolean): boolean {
        return required ? typeof this.quantification?.quality === 'number' : true;
    }

    private isSafetyQuantified(required: boolean): boolean {
        return required ? typeof this.quantification?.safety === 'number' : true;
    }

    private isSurroundingsQuantified(required: boolean): boolean {
        return required ? typeof this.quantification?.surroundings === 'number' : true;
    }

    private isImageQuantified(required: boolean): boolean {
        return required ? typeof this.quantification?.image === 'number' : true;
    }

    private isReputationQuantified(required: boolean): boolean {
        return required ? typeof this.quantification?.image === 'number' : true;
    }

    private hasEstimatedCostsForPer(): boolean {
        return !this.includedInPer || !!this.perCosts;
    }

    private hasEstimatedCosts(): boolean {
        return !!this.minCosts
            && !!this.maxCosts
            && !!this.bestGuessCosts;
    }

    private hasCountermeasures() {
        return !!this.counterMeasures
            && !!this.counterMeasuresCosts
            && !!this.counterMeasuresActionHolder;
    }
}
