import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyTypeService, LoadingSpinnerService, TisDefinitionService, ToastService} from '../../services';
import {Category, ICategoryData, IQuestionData, TisDefinition} from '../../models';
import {Subscription} from 'rxjs';
import {Question} from '../../models/definition/question';
import {
    DeleteItemModalComponent,
    TisCreateUpdateCategoryModalComponent,
    TisCreateUpdateQuestionModalComponent
} from '../../components';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {TranslateService} from '@ngx-translate/core';
import {CompanyType} from '../../models/user/user';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-page-tisversion',
    templateUrl: './page-tisversion.component.html',
    styleUrls: ['./page-tisversion.component.scss']
})
export class PageTisversionComponent implements OnInit, OnDestroy {
    public isUpdatingTis = false;
    public tisCategories: Category[];
    public companyType: CompanyType;
    private tisDefinitionId: number;
    private tisDefinition: TisDefinition;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private tisDefinitionService: TisDefinitionService,
        private modalService: VwuiModalService,
        private toastService: ToastService,
        private companyTypeService: CompanyTypeService,
        private translateService: TranslateService,
        public loadingSpinnerService: LoadingSpinnerService
    ) {
    }

    async ngOnInit(): Promise<void> {
        await this.setData();
        this.subscriptions.push(this.companyTypeService.getActiveCompanyTypeSubject().subscribe((companyType) => {
            if (!!companyType) {
                this.companyType = companyType;
                this.getTisDefinition();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public updateCategoryTables(): void {
        this.tisCategories = this.tisDefinition.getCategories();
    }

    public navigateToTisList(): void {
        this.router.navigate(['/beheer', 'tis', this.companyType.id]);
    }

    public updateTis(): void {
        const tisDefinition = this.tisDefinition.cloneData();
        this.isUpdatingTis = true;
        this.tisDefinitionService.updateTisDefinition(this.companyType, tisDefinition)
            .then(() => {
                this.toastService.openSuccess(this.translateService.instant('PAGE_TIS_VERSION.updated', {tisLabelShort: this.companyType.tisLabelShort}));
            })
            .finally(() => {
                this.isUpdatingTis = false;
            });
    }

    public openCreateCategoryModal(): void {
        const modal = this.modalService.open(
            TisCreateUpdateCategoryModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.tis.modal.create_category.title',
                    data: {
                        name: ''
                    },
                    saveText: 'BUTTON.create'
                }
            }
        );

        modal.afterClosed
            .pipe(take(1))
            .subscribe((data: ICategoryData) => {
                if (data) {
                    this.tisDefinition.addCategory(data);
                }
            });
    }

    public openUpdateCategoryModal(category: Category): void {
        const modal = this.modalService.open(
            TisCreateUpdateCategoryModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.tis.modal.update_category.title',
                    data: category.cloneData(),
                    saveText: 'BUTTON.update'
                }
            }
        );

        modal.afterClosed
            .pipe(take(1))
            .subscribe((data: ICategoryData) => {
                if (data) {
                    category.setData(data);
                }
            });
    }

    public openCreateQuestionModal(category: Category): void {
        const modal = this.modalService.open(
            TisCreateUpdateQuestionModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.tis.modal.create_question.title',
                    data: {
                        name: '',
                        possibleAnswers: []
                    },
                    saveText: 'BUTTON.create'
                }
            }
        );

        modal.afterClosed
            .pipe(take(1))
            .subscribe((data: IQuestionData) => {
                if (data) {
                    category.addSubCategoryQuestion(data);
                }
            });
    }

    public openUpdateQuestionModal(question: Question): void {
        const modal = this.modalService.open(
            TisCreateUpdateQuestionModalComponent,
            {
                closeOnBackdropClick: false,
                data: {
                    title: 'COMPONENT.tis.modal.update_question.title',
                    data: {...question.cloneData()},
                    saveText: 'BUTTON.update'
                }
            }
        );

        modal.afterClosed
            .pipe(take(1))
            .subscribe((data: IQuestionData) => {
                if (data) {
                    question.setData(data);
                }
            });
    }

    public openDeleteCategoryModal(category: Category): void {
        const modal = this.modalService.open(
            DeleteItemModalComponent,
            {
                data: {
                    title: 'COMPONENT.tis.modal.delete_category.title',
                    description: 'COMPONENT.tis.modal.delete_category.description'
                }
            }
        );

        modal.afterClosed
            .pipe(take(1))
            .subscribe((doDelete: boolean) => {
                if (doDelete) {
                    category.delete();
                }
            });
    }

    public openDeleteQuestionModal(question: Question): void {
        const modal = this.modalService.open(
            DeleteItemModalComponent,
            {
                data: {
                    title: 'COMPONENT.tis.modal.delete_question.title',
                    description: 'COMPONENT.tis.modal.delete_question.description'
                }
            }
        );

        modal.afterClosed.subscribe((doDelete: boolean) => {
            if (doDelete) {
                question.delete();
            }
        });
    }

    public moveCategoryUp(category: Category): void {
        category.moveUp();
    }

    public moveCategoryDown(category: Category): void {
        category.moveDown();
    }

    public moveQuestionUp(question: Question, base: Category): void {
        base.moveQuestionUp(question);
    }

    public moveQuestionDown(question: Question, base: Category): void {
        base.moveQuestionDown(question);
    }

    public trackTisCategory(index: number, category: Category): string {
        return `${category.id}`;
    }

    public trackTisSubCategoryQuestions(index: number, question: Question): string {
        return `${question.getParent().id}_${question.id}`;
    }

    private async setData(): Promise<void> {
        const companyTypeId = parseInt(this.route.snapshot.paramMap.get('companyTypeId'), 10);
        this.tisDefinitionId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
        await this.companyTypeService.setActiveCompanyTypeId(companyTypeId);
    }

    private getTisDefinition(): void {
        this.loadingSpinnerService.show();
        this.tisDefinitionService.getTisDefinition(this.companyType.id, this.tisDefinitionId, this.companyType.tisLabelShort)
            .then((tisDefinition) => {
                this.tisDefinition = tisDefinition;
                this.subscriptions.push(this.tisDefinition.onChange.subscribe(() => {
                    this.updateCategoryTables();
                }));
                this.updateCategoryTables();
            }).finally(() => {
                this.loadingSpinnerService.hide();
            });
    }
}
