import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LibraryService, ProjectService, StepService, ToastService, UserService} from '../../services';
import {ICurrentStepState, IProgressBarStep} from '../../interfaces';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDetails} from '../../models';
import {CloseProjectModalComponent, DeleteProjectModalComponent, DownloadPDFModalComponent, ProjectOnHoldModalComponent, ShareProjectModalComponent} from '../../components';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {PDFPageKeys, ProjectStatus, ProjectSteps} from '../../enums';
import {ProjectLibraryItemStatus} from '../../models/project/project-libary-item';

@Component({
    selector: 'app-page-project-steps',
    templateUrl: './page-project-steps.component.html',
    styleUrls: ['./page-project-steps.component.scss']
})
export class PageProjectStepsComponent implements OnInit, OnDestroy, AfterViewChecked {

    public projectDetailsInvalid = false;
    public stepPrefix: string;
    public projectId: number;
    public project: ProjectDetails;
    public stepId: number;
    public stepProgress: number;
    public currentStepState: ICurrentStepState;
    public progressBarSteps: IProgressBarStep[];
    public blinkShown = false;
    public isSaving: boolean;
    private subscriptions: Subscription[] = [];
    private canArchiveProject = false;
    private canChangeStatus = false;
    public projectSteps = ProjectSteps;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private stepService: StepService,
        private changeDetector: ChangeDetectorRef,
        private translateService: TranslateService,
        private toastService: ToastService,
        private projectService: ProjectService,
        private libraryService: LibraryService,
        private modalService: VwuiModalService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.stepService.onCurrentStepChanged().subscribe(() => {
            this.updateStep();
        }));

        this.setData();
        this.subscriptions.push(this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setData();
            }
        }));
        this.canArchiveProject = this.userService.isTenderManager();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    ngAfterViewChecked() {
        // Prevents ExpressionChangedAfterItHasBeenCheckedError
        this.changeDetector.detectChanges();
    }

    public saveProject() {
        this.isSaving = true;
        this.doSaveProject(this.project);
    }

    public setProjectToArchived(event: Event): void {
        if (this.disabledForArchiving()) {
            event.stopImmediatePropagation();
            return;
        }

        const modal = this.modalService.open(
            DeleteProjectModalComponent,
            {
                data: {
                    projectDetails: this.project
                }
            }
        );

        modal.afterClosed.subscribe((isSubmitted) => {
            if (isSubmitted === true) {
                this.router.navigate(['projecten']);
            }
        });
    }

    public setProjectToClosed(event: Event): void {
        if (!this.enabledForClosing()) {
            event.stopImmediatePropagation();
            return;
        }

        const modal = this.modalService.open(
            CloseProjectModalComponent,
            {
                data: {
                    projectDetails: this.project
                }
            }
        );

        modal.afterClosed.subscribe((isSubmitted) => {
            if (isSubmitted === true) {
                this.router.navigate(['projecten']);
            }
        });
    }

    public changeProjectOnHoldStatus(event: Event): void {
        if (this.disabledForOnHold()) {
            event.stopImmediatePropagation();
            return;
        }

        const modal = this.modalService.open(
            ProjectOnHoldModalComponent,
            {
                data: {
                    projectDetails: this.project
                }
            }
        );

        modal.afterClosed.subscribe((isSubmitted: boolean) => {
            if (isSubmitted === true) {
                this.router.navigate(['projecten']);
            }
        });
    }

    public shareProject(event: Event): void {
        if (this.disabledForNonEditors()) {
            event.stopImmediatePropagation();
            return;
        }

        const modal = this.modalService.open(
            ShareProjectModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'medium-width-modal',
                data: {
                    projectDetails: this.project
                }
            }
        );

        modal.afterClosed.subscribe((newDetails: ProjectDetails | boolean) => {
            if (newDetails !== false && newDetails instanceof ProjectDetails) {
                this.project = newDetails;
            }
        });
    }

    public projectDetailsValid($event: boolean) {
        this.projectDetailsInvalid = ($event === false);
    }

    public projectDetailsChanged($event: ProjectDetails) {
        this.project = $event;
        this.project.changed();
    }

    public downloadResult(): void {
        this.modalService.open(
            DownloadPDFModalComponent, {
                closeOnBackdropClick: false,
                closeOnEsc: false,
                modalClass: 'medium-modal',
                data: {
                    keys: {
                        [PDFPageKeys.PROJECT_DETAILS]: !this.isInExecution(),
                        [PDFPageKeys.QUICKSCAN]: this.stepId >= ProjectSteps.QUICKSCAN && !this.isInExecution(),
                        [PDFPageKeys.RISKS_OPPORTUNITIES]: this.stepId >= ProjectSteps.RISKS,
                        [PDFPageKeys.RISK_RAINBOW]: this.stepId >= ProjectSteps.RAINBOW || this.isInExecution(),
                        [PDFPageKeys.TIS_QUICKSCAN]: this.stepId >= ProjectSteps.TIS_QUICKSCAN && !this.isInExecution(),
                        [PDFPageKeys.TIS]: this.stepId >= ProjectSteps.TIS_QUESTIONS,
                        [PDFPageKeys.MANDATING_FLOW]: this.stepId >= ProjectSteps.MANDATING && this.canMandate()
                    },
                    projectDetails: this.project
                }
            }
        );
    }

    public disabledForArchiving(): string {
        if (!this.projectService.isOwner(this.project)) {
            return 'DISABLED.not_owner';
        }
        if (!this.userService.isTenderManager()) {
            return 'DISABLED.not_tender_manager';
        }
        if (this.project.status === ProjectStatus.MANDATING) {
            return 'DISABLED.in_mandate';
        }
        return '';
    }

    public disabledForOnHold(): string {
        if (this.project?.status === ProjectStatus.MANDATING) {
            return 'DISABLED.in_mandate';
        }

        // RISC-802 - Only tender managers or project owner are allowed to change the project status to on hold
        if (!this.projectService.isOwner(this.project) && !this.projectService.isCompanyTenderManager(this.project)) {
            return 'DISABLED.no_on_hold_rights';
        }

        return '';
    }

    public disabledSaveReason(): string {
        if (this.projectService.canEditProject(this.project) ||
            // RISC-668 - Risk managers and (company) admins are allowed to change the project owner even if they do not have write access
            this.projectService.canManageCompany(this.project) ) {
            if (this.projectDetailsInvalid) {
                return 'DISABLED.form_invalid';
            }
            if (this.project.isClosed()) {
                return 'DISABLED.project_closed';
            }
        } else {
            return 'DISABLED.no_edit_rights';
        }
        return '';
    }

    public disabledForNonEditors(): string {
        if (this.projectService.canEditProjectDetails(this.project)) {
            if (this.projectDetailsInvalid) {
                return 'DISABLED.form_invalid';
            }
        } else {
            return 'DISABLED.no_edit_rights';
        }
        return '';
    }

    public showInExecutionSteps(): boolean {
        return this.project && (this.project.status === ProjectStatus.IN_EXECUTION || this.project.isClosed());
    }

    public onTabChange(newStepId: number): void {
        this.stepId = Math.abs(newStepId);
    }

    private canMandate(): boolean {
        return this.project && this.project.isInMandateProcess() || this.project.isMandated();
    }

    private setData(): void {
        this.stepPrefix = `/project/${this.route.snapshot.paramMap.get('projectId')}`;
        this.projectId = parseInt(this.route.snapshot.paramMap.get('projectId'), 10);
        this.stepId = parseInt(this.route.snapshot.paramMap.get('stepId'), 10);
        this.stepService.setCurrentStep(this.stepId);

        // Only retrieve the project when we don't have one to prevent clearing filled in forms
        if (!this.project) {
            this.projectService.getProjectDetails(this.projectId).then((project) => {
                this.project = project;
                this.canChangeStatus = this.projectService.isOwner(this.project) || this.projectService.isProjectRiskManager(this.project);
                this.blinkShown = !this.canChangeStatus;
                this.updateStep();
            });
        }
    }

    private updateStep(): void {
        this.stepProgress = this.getStepProgress();

        this.currentStepState = this.stepService.getCurrentStepState();

        const progressBarSteps: IProgressBarStep[] = [];
        const accessibleSteps = this.stepService.getAccessibleSteps();

        const amountRiskProposals = this.project ? this.project.projectLibraryItems.filter((item) => {
            return item.status === ProjectLibraryItemStatus.PROPOSED && item.libraryType === 'risks';
        }).length : 0;
        const amountOpportunityProposals = this.project ? this.project.projectLibraryItems.filter((item) => {
            return item.status === ProjectLibraryItemStatus.PROPOSED && item.libraryType === 'opportunities';
        }).length : 0;
        accessibleSteps.forEach((accessibleStep, index) => {
            let tooltipText = this.translateService.instant(`STEPS.${accessibleStep}`, { tisLabelShort : this.project ? this.project.projectInfo.tisLabelShort : ''});

            let stepHasBlink = false;
            let amountProposals = 0;
            if (index === 2 && amountRiskProposals > 0) {
                stepHasBlink = true;
                amountProposals = amountRiskProposals;
            } else if (index === 3 && amountOpportunityProposals > 0) {
                stepHasBlink = true;
                amountProposals = amountOpportunityProposals;
            }

            if (amountProposals > 0) {
                if (amountProposals === 1) {
                    tooltipText += ': ' + amountProposals + this.translateService.instant(`STEPS_BLINK.single_proposal`);
                } else {
                    tooltipText += ': ' + amountProposals + this.translateService.instant(`STEPS_BLINK.multiple_proposals`);
                }
            }

            progressBarSteps.push({
                urlSlugs: [this.stepPrefix, 'step', accessibleStep],
                progress: Math.round((100 / (accessibleSteps.length - 1)) * index),
                active: accessibleStep <= this.currentStepState.currentStep,
                blink: !this.blinkShown && this.canChangeStatus && stepHasBlink
,
                tooltip: tooltipText
            });
        });

        this.progressBarSteps = progressBarSteps;

        // We only want the blinking animation to be shown once when the project is opened, otherwise it would blink
        // On every page switch which would be very annoying to the user
        this.blinkShown = true;
    }

    private getStepProgress() {
        const perStepPercentage = 100 / (this.stepService.getLastStep() - 1);

        return perStepPercentage * (this.stepService.getCurrentStep() - 1);
    }

    private doSaveProject(project: ProjectDetails) {
        this.userService.getUserInfo().then((userInfo) => {
            const changingOwner = project.owner.emailAddress !== project.newOwner.emailAddress;

            this.projectService.saveProjectDetails(project)
                .then(() => {
                    if (!changingOwner) {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_PROJECT_DETAILS.message_updated'));
                    } else {
                        this.toastService.openSuccess(this.translateService.instant('PAGE_PROJECT_DETAILS.message_moved'));
                        this.router.navigate(['projecten']);
                    }
                }, (err) => {
                    this.toastService.openError(err.code, this.translateService.instant('PAGE_PROJECT_DETAILS.error_message'));
                }).finally(() => {
                    this.isSaving = false;
                });
        });
    }

    enabledForClosing() {
        return this.isInExecution() && this.projectService.isOwner(this.project);
    }

    private isInExecution(): boolean {
        return this.project && this.project.status === ProjectStatus.IN_EXECUTION;
    }

    isClosed() {
        return this.project && this.project.status === ProjectStatus.CLOSED;
    }
}
