import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ICurrentStepState} from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class StepService {

    // Are we going to use @SessionStorage()?
    private currentStep = 1;
    private firstStep = 1;
    private lastStep = 9;
    private currentStepChanged: Subject<ICurrentStepState> = new Subject<ICurrentStepState>();

    constructor(
        private router: Router
    ) {

    }

    public setCurrentStep(currentStep: number) {
        this.currentStep = currentStep;
        this.currentStepChanged.next(this.getCurrentStepState());
    }

    public getCurrentStepState() {
        const skipSteps = [];
        // if something add skipSteps
        return {
            currentStep: this.currentStep,
            firstStep: this.firstStep,
            lastStep: this.getLastStep(),
            skipSteps
        };
    }

    public getCurrentStep(): number {
        return this.currentStep;
    }

    public onCurrentStepChanged(): Subject<ICurrentStepState> {
        return this.currentStepChanged;
    }

    public navigateToNextStep(stepPrefix: string) {
        this.currentStep = this.getNextStep();
        this.navigate(stepPrefix);
    }

    public getNextStep(): number {
        return Math.min(this.getLastStep(), this.currentStep + 1);
    }

    public navigateToPreviousStep(stepPrefix: string) {
        this.currentStep = this.getPreviousStep();
        this.navigate(stepPrefix);
    }

    public getPreviousStep(): number {
        return Math.max(this.firstStep, this.currentStep - 1);
    }

    public getLastStep(): number {
        return this.lastStep;
    }

    public isFirstStep() {
        return this.currentStep === this.firstStep;
    }

    public isLastStep() {
        return this.currentStep === this.getLastStep();
    }

    public getAccessibleSteps() {
        const accessibleSteps = [];
        const currentStepState = this.getCurrentStepState();
        for (let step = this.firstStep; step <= this.lastStep; step++) {
            if (!currentStepState.skipSteps.includes(step)) {
                accessibleSteps.push(step);
            }
        }

        this.lastStep = accessibleSteps[accessibleSteps.length - 1];
        return accessibleSteps;
    }

    private navigate(stepPrefix: string) {
        this.router.navigate([stepPrefix, this.currentStep]);
    }

}
