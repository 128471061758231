import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {IQuestionData, IQuestionType} from '../../models';
import {FormUtil} from '../../utils';
import {TrackingService} from '../../services';

@Component({
    selector: 'app-quickscan-create-update-question-modal',
    templateUrl: './tis-create-update-question-modal.component.html',
    styleUrls: ['./tis-create-update-question-modal.component.scss']
})
export class TisCreateUpdateQuestionModalComponent {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public data?: IQuestionData;
    public questionTypes: { label: string; value: string }[];

    constructor(
        public modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{ title: string; data?: IQuestionData; saveText: string }>,
        private translateService: TranslateService,
        private trackingService: TrackingService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;
        const nameValue = this.data ? this.data.name : '';
        const supportedQuestionTypes = [IQuestionType.TEXT, IQuestionType.DATE];

        const questionTypeValue = this.data && this.data.type ? this.data.type : IQuestionType.TEXT;
        const allowAttachmentsValue = this.data && this.data.allowAttachments ? this.data.allowAttachments : false;

        this.questionTypes = supportedQuestionTypes.map((type) => ({
                label: this.translateService.instant('COMPONENT.tis.modal.create_question.type.' + type),
                value: type
            }));

        this.form = new FormGroup({
            name: new FormControl(nameValue, Validators.required),
            type: new FormControl(questionTypeValue, Validators.required),
            allowAttachments: new FormControl(allowAttachmentsValue)
        });
    }

    public submit(): void {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                this.modalRef.close({...this.data, ...this.form.value});
            }
        } catch (e) {
            console.error('Creating question failed: ', e);
            this.trackingService.exception(e);
        }
    }
}
