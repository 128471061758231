import {Component, Input} from '@angular/core';
import {IRadioItem} from '../../models';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent {

    @Input() public form: FormGroup;
    @Input() public name: string;
    @Input() public label = '';
    @Input() public radioItems: IRadioItem[] = [];

    setRadioValue(value: boolean|string|number) {
        this.form.get(this.name).setValue(value);
    }

    isChecked(value: boolean|string|number) {
        return this.form.get(this.name).value === value;
    }

    public trackByTitle(index: number, radioItem: IRadioItem): string {
        return `${radioItem.title}`;
    }

}
