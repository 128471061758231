import {Component, OnDestroy, OnInit} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {LibraryCategory, ProjectDetails, ProjectLibraryItem, User} from '../../models';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectLibraryItemStatus} from '../../models/project/project-libary-item';
import {TranslateService} from '@ngx-translate/core';
import {LibraryService, ProjectService, ToastService, UserService} from '../../services';
import {FormatEuroCentsDirective} from '../../directives/format-euro-cents.directive';
import {FormUtil, ObjectUtil} from '../../utils';
import moment from 'moment';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
    selector: 'app-quantify-supplement-project-library-item-modal',
    templateUrl: './quantify-supplement-project-library-item-modal.component.html',
    styleUrls: ['./quantify-supplement-project-library-item-modal.component.scss']
})
export class QuantifySupplementProjectLibraryItemModalComponent implements OnInit, OnDestroy {
    public item: ProjectLibraryItem;
    public projectDetails: ProjectDetails;
    public categories = new BehaviorSubject<LibraryCategory[]>([]);
    public activeTab: string;
    public form: FormGroup;
    public loading = false;
    public statuses = [];
    public libraryType: string;
    public readonlyCounterMeasureDeadline = null;

    private subscriptions: Subscription[] = [];
    private isDuplicate = false;

    private originalLibraryItem: ProjectLibraryItem;
    private userInfo: User;
    private cannotChangeStatus$ = new BehaviorSubject<boolean>(true);

    constructor(
        public modalRef: VwuiModalRef,
            public modalConfig: VwuiModalConfig<{ projectLibraryItem: ProjectLibraryItem; projectDetails: ProjectDetails; tab?: string }>,
            private fb: FormBuilder,
            private translateService: TranslateService,
            private toastService: ToastService,
            private projectService: ProjectService,
            private userService: UserService,
            private libraryService: LibraryService
        ) {
        this.projectDetails = this.modalConfig.data.projectDetails;
        this.item = this.modalConfig.data.projectLibraryItem;
        this.libraryType = this.modalConfig.data.projectLibraryItem.libraryType;
        this.activeTab = this.modalConfig.data.tab ? this.modalConfig.data.tab : 'description';

        this.setStatuses();
        this.createForm();

        this.form.patchValue(ObjectUtil.cloneObject(this.item));
        if (this.item && this.item.counterMeasuresDeadline) {
            this.readonlyCounterMeasureDeadline = moment(this.item.counterMeasuresDeadline).format('DD-MM-YYYY');
            this.form.patchValue({counterMeasuresDeadline: moment(this.item.counterMeasuresDeadline).format('YYYY-MM-DD')});
        }

        this.handleFormControls();
    }

    ngOnInit() {
        this.updateCategories();
        this.item.remarks.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
        this.userService.getUserInfo().then((userInfo) => this.userInfo = userInfo);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    public clone(): void {
        this.originalLibraryItem = this.item;
        this.item = new ProjectLibraryItem(ObjectUtil.cloneObject(this.item));
        this.item.remarks.map((remark) => {
            remark.id = undefined;
        });
        this.item.id = undefined;
        this.activeTab = 'riskMitigation';
        this.isDuplicate = true;
        this.toastService.open({
            type: 'error',
            message: this.translateService.instant('COMPONENT.library_mgmt.library_type.' + this.libraryType + '.project_specific.duplicate_item.info'),
            duration: 8000,
            dismiss: true
        });
    }

    onTabChange(newTab: string) {
        this.activeTab = newTab;
    }

    async submit() {
        FormUtil.validate(this.form);

        if (this.loading || !this.form.valid) {
            return;
        }

        try {
            this.loading = true;

            let newValue: ProjectLibraryItem = {...this.item, ...this.form.value};

            if (newValue.counterMeasuresCosts) {
                newValue.counterMeasuresCosts = FormatEuroCentsDirective.convertToNumber(newValue.counterMeasuresCosts);
            }
            if (newValue.minCosts) {
                newValue.minCosts = FormatEuroCentsDirective.convertToNumber(newValue.minCosts);
            }
            if (newValue.bestGuessCosts) {
                newValue.bestGuessCosts = FormatEuroCentsDirective.convertToNumber(newValue.bestGuessCosts);
            }
            if (newValue.maxCosts) {
                newValue.maxCosts = FormatEuroCentsDirective.convertToNumber(newValue.maxCosts);
            }
            if (newValue.perCosts) {
                newValue.perCosts = FormatEuroCentsDirective.convertToNumber(newValue.perCosts);
            }
            if (newValue.counterMeasuresDeadline) {
                newValue.counterMeasuresDeadline = moment(newValue.counterMeasuresDeadline).format('YYYY-MM-DD');
            }

            if (!this.validFinancialCosts(newValue)) {
                return;
            }

            if (this.originalLibraryItem) {
                newValue.libraryItemParentId = this.originalLibraryItem.id;
            }

            let newItems: ProjectLibraryItem[];
            if (this.item.id === undefined) {
                newItems = await this.projectService.addProjectLibraryItem(this.projectDetails, this.libraryType, newValue)
                    .then((project) => project.projectLibraryItems);
            } else {
                newValue = await this.projectService.updateProjectLibraryItem(newValue);
            }

            this.modalRef.close({action: this.isDuplicate ? 'duplicated' : 'submit', item: newValue, items: newItems});
        } finally {
            this.loading = false;
        }
    }

    async remove() {
        if (this.loading) {
            return;
        }

        try {
            this.loading = true;

            await this.projectService.deleteProjectLibraryItem(this.item);
            this.modalRef.close({action: 'deleted'});
        } finally {
            this.loading = false;
        }
    }

    public getAddRemarkDisabledReason() {
        if (this.projectDetails.isOnHold()) {
            return 'DISABLED.project_on_hold';
        } else if (this.projectDetails.isClosed()) {
            return 'DISABLED.project_closed';

        } else if (this.hasNewRemark()) {
            return '';
        }

        return 'COMPONENT.quantify_supplement_project_library.form.add_remark';
    }

    public getCloneDisabledReason() {
        return this.getDeleteDisabledReason();
    }

    public getDeleteDisabledReason() {
        if (this.projectDetails.isOnHold()) {
            return 'DISABLED.project_on_hold';
        } else if (this.projectDetails.isClosed()) {
            return 'DISABLED.project_closed';
        } else if (this.canAlwaysDelete()) {
            return null;
        }

        if (this.canEditProject()) {
            if (this.item.status !== ProjectLibraryItemStatus.PROPOSED) {
                return 'DISABLED.not_owner';
            } else if (!this.isItemOwner()) {
                return 'DISABLED.not_item_owner';
            } else {
                return null;
            }
        }

        return 'DISABLED.no_edit_rights';
    }

    public getSaveDisabledReason() {
        if (this.projectDetails.isOnHold()) {
            return 'DISABLED.project_on_hold';

        } else if (this.canEditProject()) {
            return '';
        }

        return 'DISABLED.no_edit_rights';
    }

    public canEditProject(): boolean {
        return this.projectService.canEditProjectLibraryItems(this.projectDetails)
            && !this.projectDetails.isOnHold()
            && !this.projectDetails.isClosed();
    }

    public canChangeStatus(): boolean {
        return this.projectService.isOwner(this.projectDetails) || this.projectService.isProjectRiskManager(this.projectDetails);
    }

    public canAlwaysDelete(): boolean {
        return this.projectService.isOwner(this.projectDetails) || this.projectService.isProjectRiskManager(this.projectDetails);
    }

    public hasNewRemark(): boolean {
        return this.form.value !== undefined && this.form.value.newRemark != null && this.form.value.newRemark.length > 0;
    }

    public async addRemark() {
        if (!this.hasNewRemark()) {
            return;
        }
        this.item.newRemark = this.form.value.newRemark;
        this.item = await this.projectService.updateProjectLibraryItem(this.item);
        this.form.patchValue({newRemark: null});
    }

    public setInPerChoice() {
        this.form.get('includedInPer').setValue(!this.form.get('includedInPer').value);
    }

    private setStatuses() {
        const buildItem = (status: string) => ({
            id: status,
            label: this.translateService.instant(`PAGE_PROJECT_LIBRARY_ITEMS.library_type.risks.status.${status}`)
        });
        this.statuses = Object.values(ProjectLibraryItemStatus).map(status => buildItem(status));

        this.cannotChangeStatus$.next(!this.canChangeStatus());
    }

    private createForm() {
        this.form = this.fb.group({
            subject: new FormControl({}, [Validators.maxLength(255), Validators.required]),
            libraryCategoryId: new FormControl(undefined, Validators.required),
            itemOwner: new FormControl('', Validators.maxLength(255)),
            minCosts: [null],
            bestGuessCosts: [null],
            maxCosts: [null],
            perCosts: [null],
            riskDescription: new FormControl({}, Validators.maxLength(1024)),
            riskClient: new FormControl({}),
            causeDescription: new FormControl({}, Validators.maxLength(1024)),
            effectDescription: new FormControl({}, Validators.maxLength(1024)),
            status: [null],
            includedInPer: false,
            counterMeasuresActionHolder: new FormControl({}, Validators.maxLength(255)),
            counterMeasures: new FormControl({}, Validators.maxLength(1024)),
            counterMeasuresCosts: [null],
            counterMeasuresDeadline: [null],
            newRemark: new FormControl({}, Validators.maxLength(1024)),
            quantification: [{}]
        });

        this.handleFormControls();
    }

    private handleFormControls(): void {
        this.form.enable();

        this.subscriptions.push(this.form.get('counterMeasuresCosts').valueChanges.subscribe((value) => {
            const numberValue = FormatEuroCentsDirective.convertToNumber(value);
            const moneyValue = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());
            this.item.counterMeasuresCosts = FormatEuroCentsDirective.convertToNumber(moneyValue);

            this.form.get('counterMeasuresCosts').setValue(moneyValue, {emitEvent: false});
        }));
        this.subscriptions.push(this.form.get('minCosts').valueChanges.subscribe((value) => {
            const numberValue = FormatEuroCentsDirective.convertToNumber(value);
            const moneyValue = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

            this.form.get('minCosts').setValue(moneyValue, {emitEvent: false});
        }));
        this.subscriptions.push(this.form.get('bestGuessCosts').valueChanges.subscribe((value) => {
            const numberValue = FormatEuroCentsDirective.convertToNumber(value);
            const moneyValue = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

            this.form.get('bestGuessCosts').setValue(moneyValue, {emitEvent: false});
        }));
        this.subscriptions.push(this.form.get('maxCosts').valueChanges.subscribe((value) => {
            const numberValue = FormatEuroCentsDirective.convertToNumber(value);
            const moneyValue = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

            this.form.get('maxCosts').setValue(moneyValue, {emitEvent: false});
        }));
        this.subscriptions.push(this.form.get('perCosts').valueChanges.subscribe((value) => {
            const numberValue = FormatEuroCentsDirective.convertToNumber(value);
            const moneyValue = FormatEuroCentsDirective.convertNumberToEuros(numberValue, this.translateService.getDefaultLang());

            this.form.get('perCosts').setValue(moneyValue, {emitEvent: false});
        }));

        // RISC-426 - Automatic status changes when the item owner is changed
        this.subscriptions.push(this.form.get('counterMeasuresActionHolder').valueChanges.subscribe((value) => {
            const statusValue = this.form.get('status').value;

            if (value != null && value.length > 0 &&
                (statusValue === ProjectLibraryItemStatus.NEW || statusValue === null)) {
                this.form.get('status').setValue(ProjectLibraryItemStatus.CURRENT, {emitEvent: false});
            } else if (!value && (statusValue === ProjectLibraryItemStatus.CURRENT)) {
                this.form.get('status').setValue(ProjectLibraryItemStatus.NEW, {emitEvent: false});
            }
        }));

        if (!this.canEditProject()) {
            this.form.disable();
        }
    }

    private validFinancialCosts(newValue: ProjectLibraryItem) {
        if (newValue.minCosts !== undefined && newValue.minCosts !== null) {
            if (newValue.maxCosts !== undefined && newValue.maxCosts !== null) {
                if (newValue.minCosts > newValue.maxCosts) {
                    this.openWarning(`ERRORS.${this.libraryType}.min_higher_than_max`);
                    return false;
                }
            }
            if (newValue.bestGuessCosts !== undefined && newValue.bestGuessCosts !== null) {
                if (newValue.minCosts > newValue.bestGuessCosts) {
                    this.openWarning(`ERRORS.${this.libraryType}.min_higher_than_best_guess`);
                    return false;
                }
            } else if (newValue.maxCosts !== undefined && newValue.maxCosts !== null) {
                this.openWarning(`ERRORS.${this.libraryType}.min_max_but_no_best_guess`);
                return false;
            }
        }
        if (newValue.bestGuessCosts !== undefined && newValue.bestGuessCosts !== null) {
            if (newValue.bestGuessCosts > 999000000000000) {
                this.openWarning('ERRORS.too_many_euros');
                return false;
            }
            if (newValue.maxCosts !== undefined && newValue.maxCosts !== null) {
                if (newValue.bestGuessCosts > newValue.maxCosts) {
                    this.openWarning(`ERRORS.${this.libraryType}.best_guess_higher_than_max`);
                    return false;
                }
            }
        }

        if (
            newValue.counterMeasuresCosts > 999000000000000
            || newValue.bestGuessCosts > 999000000000000
            || newValue.maxCosts > 999000000000000
            || newValue.minCosts > 999000000000000
        ) {
            this.openWarning('ERRORS.too_many_euros');
            return false;
        }

        return true;
    }

    private openWarning(warningMessage: string) {
        this.toastService.open({
            type: 'error',
            duration: 8000,
            icon: 'exclamation',
            code: '0',
            message: this.translateService.instant(warningMessage),
            dismiss: true
        });
    }

    getAllItems() {
        if (this.libraryType === 'risks') {
            return this.projectDetails.getRisks();
        } else {
            return this.projectDetails.getOpportunities();
        }
    }

    private isItemOwner(): boolean {
        if (this?.userInfo) {
            return this.item.createdBy === this.userInfo.uniqueName;
        }
        return false;
    }

    private updateCategories(): void {
        this.categories.next([]);
        if (this.item.isProjectSpecific) {
            switch (this.item.libraryType) {
                case 'risks':
                    this.libraryService.getRiskLibary(this.modalConfig.data.projectDetails.companyTypeId).then((result) => {
                        this.categories.next(result.categories);
                    });
                    break;
                default:
                    this.libraryService.getOpportunitiesLibary(this.modalConfig.data.projectDetails.companyTypeId).then((result) => {
                        this.categories.next(result.categories);
                    });
                    break;
            }
        }
    }
}
