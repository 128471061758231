import {FormControl, FormGroup} from '@ng-stack/forms';
import {ILibraryItemSortOptionsData, ItemSorting, SortableTypes, SortingColumns} from './sort-data';
import {ProjectLibraryItem} from '../project/project-libary-item';

export class LibraryItemSort {

    private readonly sortControl: FormGroup<ILibraryItemSortOptionsData>;
    private readonly ItemSorting = ItemSorting;

    constructor(
        itemSort: ILibraryItemSortOptionsData
    ) {
        this.sortControl = new FormGroup<ILibraryItemSortOptionsData>({
            opportunityColumn: new FormControl(itemSort.opportunityColumn),
            opportunityOption: new FormControl(itemSort.opportunityOption),
            riskColumn: new FormControl(itemSort.riskColumn),
            riskOption: new FormControl(itemSort.riskOption)
        });
    }

    public getControl(): FormGroup<ILibraryItemSortOptionsData> {
        return this.sortControl;
    }

    public isColumnActive(column: string, type: string): boolean {
        return type === SortableTypes.RISK && column === this.getControl().value.riskColumn
            || type === SortableTypes.OPPORTUNITY && column === this.getControl().value.opportunityColumn;
    }

    public updateControl(data: ILibraryItemSortOptionsData) {
        this.sortControl.setValue({
            opportunityOption: data.opportunityOption,
            opportunityColumn: data.opportunityColumn,
            riskOption: data.riskOption,
            riskColumn: data.riskColumn
        });
    }

    public getRiskColumn() {
        return this.sortControl.value.riskColumn;
    }

    public getOpportunityColumn() {
        return this.sortControl.value.opportunityColumn;
    }

    public sortRiskAndOpportunityLibraryItems(type: string, column: string, reorder = true, filteredItems: ProjectLibraryItem[]): void {
        const libraryValues = this.getControl().value;

        if (reorder) {
            if (type === SortableTypes.RISK) {
                libraryValues.riskColumn = column;
                libraryValues.riskOption = (libraryValues.riskOption === ItemSorting.ASCENDING ? ItemSorting.DESCENDING : ItemSorting.ASCENDING);
            } else {
                libraryValues.opportunityColumn = column;
                libraryValues.opportunityOption = (libraryValues.opportunityOption === ItemSorting.ASCENDING ? ItemSorting.DESCENDING : ItemSorting.ASCENDING);
            }
        }

        filteredItems.sort((item1, item2) => {
            let value1;
            let value2;

            if (column === SortingColumns.SCORE) {
                value1 = item1.getScore() === null ? 0 : item1.getScore();
                value2 = item2.getScore() === null ? 0 : item2.getScore();
            }

            if (column === SortingColumns.IN_PER) {
                value1 = item1.perCosts === null ? 0 : item1.perCosts;
                value2 = item2.perCosts === null ? 0 : item2.perCosts;
            }

            if (column === SortingColumns.BEST_GUESS) {
                value1 = item1.bestGuessCosts === null ? 0 : item1.bestGuessCosts;
                value2 = item2.bestGuessCosts === null ? 0 : item2.bestGuessCosts;
            }

            if (value1 === null || value2 === null) {
                return 1;
            } else {
                if (type === SortableTypes.RISK ? libraryValues.riskOption === ItemSorting.DESCENDING : libraryValues.opportunityOption === ItemSorting.DESCENDING) {
                    return Math.sign(value1 - value2);
                }
                return Math.sign(value2 - value1);
            }
        });

        if (reorder) {
            this.updateControl(libraryValues);
        }
    }
}
