import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ICompany, IRadioItem} from '../../models';
import {FormUtil} from '../../utils';
import {CompanyTypeService, TrackingService, UserService} from '../../services';
import {CompanyType, IUserIdentity} from '../../models/user/user';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ApiProjectCreationType} from '../../enums';

class Member {
    user: IUserIdentity;
    editRights: boolean;
    adminRights: boolean;

    constructor(user: IUserIdentity, editRights: boolean, adminRights: boolean) {
        this.user = user;
        this.editRights = editRights;
        this.adminRights = adminRights;
    }

    public toggleEdit(): void {
        this.editRights = !this.editRights;
        if (this.editRights) {
            this.disableAdmin();
        }
    }

    toggleAdmin() {
        this.adminRights = !this.adminRights;
        this.disableEdit();
        if (this.adminRights) {
            this.disableEdit();
        }
    }

    disableAdmin() {
        this.adminRights = false;
    }

    disableEdit() {
        this.editRights = false;
    }
}

@Component({
    selector: 'app-create-company-modal',
    templateUrl: './create-update-company-modal.component.html',
    styleUrls: ['./create-update-company-modal.component.scss']
})
export class CreateUpdateCompanyModalComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public loading = false;

    public title: string;
    public saveText: string;
    public data?: ICompany;
    public companyTypes: CompanyType[];
    private members: Member[];

    private subscriptions: Subscription[] = [];
    public identitiesToExclude$: BehaviorSubject<IUserIdentity[]> = new BehaviorSubject([]);
    public apiProjectCreationOptions: IRadioItem[] = [
        {
            title: 'COMPONENT.company.apiProjectCreation.off',
            value: ApiProjectCreationType.OFF
        },
        {
            title: 'COMPONENT.company.apiProjectCreation.on',
            value: ApiProjectCreationType.ON
        },
        {
            title: 'COMPONENT.company.apiProjectCreation.only',
            value: ApiProjectCreationType.API_CREATION_ONLY
        }
    ];

    constructor(
        public modalRef: VwuiModalRef,
        private companyTypeService: CompanyTypeService,
        public modalConfig: VwuiModalConfig<{ title: string; data?: ICompany; saveText: string }>,
        private trackingService: TrackingService,
        private userService: UserService
    ) {
        this.title = modalConfig.data.title;
        this.saveText = modalConfig.data.saveText;
        this.data = modalConfig.data.data;
        this.members = this.data ? this.constructMembers(this.data) : [];

        this.companyTypes = [];
        this.companyTypeService.getCompanyTypes().then((types) => {
            this.userService.getUserInfo().then((userInfo) => {
                this.companyTypes = types
                    .filter(type => type.riskManagers.map((identity) => identity.emailAddress).includes(userInfo.uniqueName));
            });
        });

        const nameValue = this.data ? this.data.name : '';
        const companyTypeValue = this.data ? this.data.companyType : '';
        this.form = new FormGroup({
            name: new FormControl(nameValue, Validators.required),
            companyCode: new FormControl(this.data ? this.data.companyCode : '', [this.conditionalRequiredValidator(() => this.form.get('apiProjectCreation').value !== ApiProjectCreationType.OFF), Validators.maxLength(100)]),
            apiProjectCreation: new FormControl(this.data ? this.data.apiProjectCreation : false),
            companyType: new FormControl(companyTypeValue, Validators.required),
            user: new FormControl(null)
        });
        this.updateExclusionList();
    }

    async submit() {
        try {
            FormUtil.validate(this.form);

            if (this.form.valid) {
                const updatedValues = {...this.data};
                updatedValues.name = this.form.value.name;
                updatedValues.companyType = this.form.value.companyType;
                updatedValues.companyCode = this.form.value.companyCode;
                updatedValues.apiProjectCreation = this.form.value.apiProjectCreation;
                updatedValues.editors = this.members
                    .filter(member => member.editRights)
                    .map(member => member.user);
                updatedValues.viewers = this.members
                    .filter(member => !member.editRights)
                    .filter(member => !member.adminRights)
                    .map(member => member.user);
                updatedValues.admin = this.members.find(member => member.adminRights)?.user;
                this.modalRef.close(updatedValues);
            }
        } catch (e) {
            console.error('Updating item failed: ', e);
            this.trackingService.exception(e);
        }
    }

    public getMembers(): Member[] {
        return this.members;
    }

    public isAddMemberButtonDisabled(): boolean {
        return !(this.form.valid && this.form.value.user);
    }

    private constructMembers(data: ICompany): Member[] {
        const members: Member[] = [];
        data.editors.forEach((editor) => {
            members.push(new Member(editor, true, false));
        });
        data.viewers.forEach((viewer) => {
            if (!data.editors.includes(viewer)) {
                members.push(new Member(viewer, false, false));
            }
        });
        if (data.admin) {
            const existingMember = members.find((aMember) => aMember.user.emailAddress === data.admin.emailAddress);
            if (existingMember) {
                existingMember.adminRights = true;
            } else {
                members.push(new Member(data.admin, false, true));
            }
        }
        return members;
    }

    public toggleAccess($event: MouseEvent, member: Member): void {
        $event.preventDefault();
        member.toggleEdit();
    }

    public toggleAdminAccess($event: MouseEvent, member: Member): void {
        $event.preventDefault();
        this.members.forEach((projectMember) => {
            if (projectMember.user.emailAddress === member.user.emailAddress) {
                projectMember.toggleAdmin();
            } else {
                projectMember.disableAdmin();
            }
        });
    }

    public removeMember(member: Member): void {
        this.members = this.members.filter(aMember => aMember.user.emailAddress !== member.user.emailAddress);
        this.updateExclusionList();
    }

    public addMember() {
        const user = this.form.value.user;
        if (this.form.valid && user) {
            this.members.push(new Member(user, false, false));
            this.form.controls.user.setValue(null);
            this.updateExclusionList();
        }
    }

    public conditionalRequiredValidator(predicate) {
        return (formControl => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return Validators.required(formControl);
            }
            return null;
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(this.form.controls.apiProjectCreation.valueChanges
            .subscribe(value => {
                this.form.controls.companyCode.updateValueAndValidity();
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    private updateExclusionList(): void {
        this.identitiesToExclude$.next(this.members.map((member) => member.user));
    }
}
