import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IToastData, ToastModel} from '../../models';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorCode} from '../../models';

@Injectable()
export class ToastService {

    private toastRef;
    private toastTid;
    private silentToast = false;

    constructor(
        private toastService: ToastrService,
        private translateService: TranslateService
    ) {
    }

    public handleHttpError(error: HttpErrorResponse) {
        const errorCode = new ErrorCode(error);
        const errorMessage = this.translateService.instant('ERRORS.error_code.' + errorCode.errorCode);

        this.openError(errorCode.httpCode, errorMessage);
    }

    /**
     * Show a toast with the message
     *
     * @param toast <IToastData>
     */
    public open(toast: IToastData): void {
        this.close();

        if (this.silentToast) {
            return;
        }

        const settings = new ToastModel(toast);
        let template = `${settings.message}`;

        if (settings.icon) {
            template = `<i class="icon fa fa-${settings.icon}"></i>` + template;
        }
        if (settings.code) {
            template += `<small>${settings.code}</small>`;
        }

        // Quirk for ngx-toastr who needs a timeout... :(
        setTimeout(() => {
            this.toastRef = this.toastService.show(`${template}`, '', {
                toastClass: `toast-${settings.type}`,
                positionClass: 'toast-bottom',
                titleClass: 'toast-title',
                messageClass: 'toast-message',
                enableHtml: true,
                disableTimeOut: true,
                closeButton: settings.dismiss,
                tapToDismiss: settings.dismiss
            });
            this.toastTid = setTimeout(() => {
                this.close();
            }, settings.duration);
        });
    }

    /**
     * Utility method for error toast
     *
     * @param errorCode <number|string>
     * @param message <string>
     * @param duration <number>
     */
    public openError(errorCode: number|string, message: string, duration: number = 8000): void {
        this.open({
            type: 'error',
            duration,
            icon: 'exclamation',
            code: `${errorCode}`,
            message,
            dismiss: true
        });
    }

    /**
     * Utility method for success toast
     *
     * @param message <string>
     */
    public openSuccess(message: string): void {
        this.open({
            type: 'success',
            icon: 'check',
            message
        });
    }

    /**
     * Disable the toasts from being visible
     * Used only in PDF rendering cases
     */
    public disableToasts(): void {
        this.silentToast = true;
    }

    /**
     * Close any visible toast
     */
    private close(): void {
        if (this.toastRef) {
            this.toastService.clear(this.toastRef.toastId);
            this.toastRef = undefined;
        }
        if (this.toastTid) {
            clearTimeout(this.toastTid);
            this.toastTid = undefined;
        }
    }

}
